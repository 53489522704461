import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap'; 

import api from '../services/api';
import { API_ROUTES } from '../services/apiRoutes';
import { useUser } from '../general/UserProvider';
import { DataRoomContext } from './DisplayDataRoom';
import { PermissionsProvider } from '../hooks/permissions/useUserPermissions';

import InitDataRoom from './InitDataRoom';
import ListDataRooms from './ListDataRooms';
import Layout from '../general/Layout';

function Platform() {
    const { token } = useUser();
    const [dataRooms, setDataRooms] = useState([]);

    useEffect(() => {
        const fetchDataRooms = async () => {
            if (!token) return;

            try {
                const response = await api.get(API_ROUTES.LIST_DATAROOMS);
                setDataRooms(response.data);
            } catch (error) {
                console.error("Error while collecting Dataroom information", error);
            }
        };

        fetchDataRooms();
    }, [token]);

    return (
        <DataRoomContext.Provider value={null}>
            <PermissionsProvider>
                <Layout>
                    {dataRooms && dataRooms.length === 0 ? 
                        <InitDataRoom /> 
                        :
                        <Container fluid>
                            <ListDataRooms dataRooms={dataRooms} />
                        </Container>
                    }
                </Layout>
            </PermissionsProvider>
        </DataRoomContext.Provider>
    );
}

export default Platform;