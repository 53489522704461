import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function FolderSelector({ folders, value, handleInputChange, displayLabel = false, id="defaultFolderSelectorId", allOption = false }) {
  const { t } = useTranslation();
  const buildOptions = (folders, level = 0) => {
    const style = (folder) => {
      if (level === 0) return folder.name; 
      return ("→ ".repeat(level) + folder.name);
    };

    return folders.reduce((acc, folder) => {
      acc.push(
        <option key={folder.id} value={folder.id}>
          {style(folder)}
        </option>
      );
      if (folder.subcategories) {
        acc = acc.concat(buildOptions(folder.subcategories, level + 1));
      }
      return acc;
    }, []);
  };

  return (
    <Form.Group id={id}>
      {displayLabel && <Form.Label>{t('general.folder')}</Form.Label>}
      <Form.Control as="select" value={value} onChange={handleInputChange("folder_id")} name="folder_id" className="folder-select">
        <option value="" disabled>{t('general.selectFolder')}</option>
        {allOption && <option value="all">{t('general.allFolders')}</option>}
        {buildOptions(folders)}
      </Form.Control>
    </Form.Group>
  );
}

export default FolderSelector;
