import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col, Nav } from 'react-bootstrap';
import CommentSection from '../../general/CommentSection';
import ActivitiesList from '../../general/ActivitiesList';
import NoteInformation from './NoteInformation';

import useCommentCount from '../../../hooks/comments/useCommentCount';

function SideTab({ activeTab, setActiveTab, noteInfo, closeModal, deleteNote }) {
    console.log(noteInfo);
    const { t } = useTranslation();
    const canComment = noteInfo?.permissions?.Comment;

    const [commentCount, setCommentCount] = useCommentCount(noteInfo?.note_id, "Note");

    const updateCommentCount = useCallback((delta) => {
        setCommentCount(prevCount => prevCount + delta);
    }, [setCommentCount]);

    const renderContent = () => {
        switch (activeTab) {
            case 'WSContentComments':
                return <CommentSection objectId={noteInfo?.note_id} objectType="Note" onCommentChange={updateCommentCount} canComment={canComment} />;
            case 'WSContentActivities':
                return <ActivitiesList object_type="NOTE" object_id={noteInfo?.note_id} />;
            case 'WSContentInformation':
                return <NoteInformation info={noteInfo} commentCount={commentCount} closeModal={closeModal} deleteNote={deleteNote} />;
            default:
                return null;
        }
    };
    
    return (
        <Col sm={4} className="WSCardContentActivities h-100 p-2">
            <Row className="mb-2 ActivitiesMenu">
                <Col xs={12}>
                    <Nav 
                        variant="pills" 
                        activeKey={activeTab}
                        onSelect={selectedKey => setActiveTab(selectedKey)}
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="WSContentComments">{t("comment.title")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="WSContentActivities">{t("pages.activities.title")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="WSContentInformation">{t("general.information")}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            {renderContent()}
        </Col>
    );
}
export default SideTab;
