import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import GenericModal from '../../general/GenericModal';
import WSCardContent from './wsCardContent/WSCardContent';
import { formatDate } from '../../utils/Date';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';
import { useUser } from '../../general/UserProvider';
import { cleanMarkdown } from '../../utils/Markdown';

import './WSCard.css';

function WSCard({ noteId, deleteNote, dataroom_id, directAccessViaURL = false, preloadedNote }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState(preloadedNote?.title || "");
    const [content, setContent] = useState(preloadedNote?.content || "");
    const { token } = useUser();
    const [noteInfo, setNoteInfo] = useState(preloadedNote || null);

    const fetchFullNoteData = useCallback(async () => {
        if(!token) return;
        
        try {
            const response = await api.get(API_ROUTES.NOTE_BY_ID(dataroom_id, noteId));
            const data = response.data;
            const fullNoteInfo = {
                id: noteId,
                title: data.title,
                content: data.content,
                created_by: data.created_by,
                created_at: data.created_at,
                updated_at: data.updated_at,
                tags: data.folders.map(sub => sub.name),
                attachments: ["research.pdf", "data.xlsx"],
                permissions: data.permissions  
            };
            setNoteInfo(fullNoteInfo);
            setTitle(data.title);
            setContent(data.content);
        } catch (error) {
            console.error("Error fetching note data: ", error);
        }
    }, [token, dataroom_id, noteId]);

    const openModal = useCallback(() => {
        setShowModal(true);
        if (!noteInfo || Object.keys(noteInfo).length === 0) {
            fetchFullNoteData();
        }
    }, [noteInfo, fetchFullNoteData]);

    const closeModal = useCallback(() => {
        if(directAccessViaURL) {
            navigate(`/dataroom/${dataroom_id}/workspace`);
        } else {
            setShowModal(false);
        }
    }, [directAccessViaURL, navigate, dataroom_id]);

    useEffect(() => {
        if (directAccessViaURL) {
            fetchFullNoteData();
            setShowModal(true);
        }
    }, [directAccessViaURL, fetchFullNoteData]);

    return (
        <Col sm={4} className="mb-4">
            <div className="wscard" onClick={openModal}>
                <h3 className="wscard-title">{title}</h3>
                <div className="wscard-content">{cleanMarkdown(content.substring(0, 100) + "...")}</div>
                <div className="wscard-footer">
                    <span>{formatDate(noteInfo?.created_at, t)}</span>
                    <i className="bi bi-arrow-right"></i>
                </div>
            </div>
            <GenericModal 
                show={showModal} 
                onHide={closeModal} 
                title={<><i className="bi bi-card-text icon-spacing"></i> {t("pages.workspace.cardTitle")}</>} 
                fullscreen={true} 
                noPadding={true}>
                <WSCardContent 
                    title={title} 
                    setTitle={setTitle} 
                    content={content} 
                    setContent={setContent} 
                    noteId={noteId} 
                    closeModal={closeModal} 
                    deleteNote={deleteNote} 
                    noteInfo={noteInfo}
                />
            </GenericModal>
        </Col>
    );
}

export default WSCard;