import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { processContent } from '../../../utils/PostProcessContent';
import './markdown-styles.css';

export const ProcedureConclusion = ({
    conclusion,
    isEditing,
    editedConclusion,
    onStartEditing,
    onSave,
    onCancel,
    onChange,
    status,
    updating
}) => {
    const { t } = useTranslation();
    const renderMarkdown = (content) => {
        const processedContent = processContent(content);
        
        return (
            <div className="markdown-content">
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                >
                    {processedContent}
                </ReactMarkdown>
            </div>
        );
    };

    return (
        <section className="conclusion-section mt-4">
            <h2 className="section-title-conclusion">{t("general.conclusion")}</h2>
            {isEditing ? (
                <Form>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        value={editedConclusion}
                        onChange={onChange}
                        className="conclusion-textarea"
                        disabled={status === 'Completed'}
                        placeholder="Enter your conclusion here... (Markdown supported)"
                    />
                    <div className="text-muted small mt-1">
                        {t("pages.workflow.markdownFormattingIsSupported")}
                    </div>
                    <div className="mt-3">
                        <Button 
                            variant="primary" 
                            onClick={onSave} 
                            className="me-2"
                            disabled={status === 'Completed' || updating}
                        >
                            {updating ? t("general.saving") : t("general.save")}
                        </Button>
                        <Button 
                            variant="secondary" 
                            onClick={onCancel}
                            disabled={status === 'Completed' || updating}
                        >
                            {t("general.cancel")}
                        </Button>
                    </div>
                </Form>
            ) : (
                <>
                    <div className={`markdown-wrapper ${status === 'Completed' ? 'text-muted' : ''}`}>
                        {renderMarkdown(conclusion)}
                    </div>
                    <Button 
                        variant="outline-primary" 
                        onClick={onStartEditing}
                        disabled={status === 'Completed'}
                        className="me-2"
                    >
                        <i className="bi bi-pencil me-2"></i>
                        {t("general.edit")}
                    </Button>
                </>
            )}
        </section>
    );
};

export default ProcedureConclusion;