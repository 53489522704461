// AIDocumentSuggestions.jsx
import React, { useState } from 'react';
import { useAISuggestions } from '../../../../../hooks/workflow/useAISuggestions';
import AnalysisForm from './AIDocumentSuggestions/AnalysisForm';
import SearchBar from './AIDocumentSuggestions/SearchBar';
import SuggestionsContainer from './AIDocumentSuggestions/SuggestionsContainer';
import SuggestionsList from './AIDocumentSuggestions/SuggestionsList';

const AIDocumentSuggestions = ({ onRequest, onSelect, isProcessing }) => {
  const [criteria, setCriteria] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const { 
    suggestions,
    selectedDocs,
    searchQuery,
    showSuggestions,
    error,
    setSearchQuery,
    handleSubmit,
    handleDocumentClick,
    handleLinkSelected
  } = useAISuggestions(onRequest, onSelect);

  // Ensure suggestions is always an array
  const validSuggestions = Array.isArray(suggestions) ? suggestions : [];
  const hasSuggestions = validSuggestions.length > 0;

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (criteria.trim()) {
      setIsAnalyzing(true);
      try {
        await handleSubmit(criteria);
      } finally {
        setIsAnalyzing(false);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="d-flex flex-column gap-4">
      <AnalysisForm
        criteria={criteria}
        setCriteria={setCriteria}
        onSubmit={handleFormSubmit}
        isProcessing={isAnalyzing}
        error={error}
      />

      {showSuggestions && hasSuggestions && (
        <div className="d-flex flex-column gap-3">
          <SearchBar
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
            selectedCount={selectedDocs.size}
            onLinkClick={handleLinkSelected}
            isProcessing={isProcessing}
          />

          <SuggestionsContainer isEmpty={!hasSuggestions}>
            <SuggestionsList
              suggestions={validSuggestions}
              selectedDocs={selectedDocs}
              onDocumentClick={handleDocumentClick}
            />
          </SuggestionsContainer>
        </div>
      )}
    </div>
  );
};

export default AIDocumentSuggestions;