import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

function DeleteForm( {item, setModalProperties, submitDelete} ) {
    const { t } = useTranslation();
    const setModalIsOpen = (value) => {
        setModalProperties(prevProps => ({
            ...prevProps,
            isOpen: value
        }));
    }

    return (
        <>
            <p>{t('pages.documents.deleteConfirmation')} <i>{item.name}</i> ?</p>
            <Button className="mt-3" variant="secondary" onClick={() => setModalIsOpen(false)}>
                {t('general.close')}
            </Button>
            <Button className="mt-3 mx-3" variant="danger" onClick={() => submitDelete(item)}>
                {t('general.yesDelete')}
            </Button>
        </>
    );
};

export default DeleteForm;