import React from 'react';
import { useTranslation } from 'react-i18next';

import CommentSection from '../../general/CommentSection';
import FolderActions from './FolderActions';
import { formatDate } from '../../../utils/Date';

function FolderRow({ item, setModalProperties, deleteFolder, setCurrentFolder, updateFolder, refetch, handleFolderClick, closeModal }) {
    // const canComment = item?.permissions?.Comment;
    const canComment = true;
    const { t } = useTranslation();

    const handleComments = () => {
        setModalProperties(prevProps => ({
            ...prevProps,
            title: t('comment.title'),
            content: <CommentSection 
                objectId={item.id} 
                objectType="Folder" 
                onCommentChange={() => {}}
                canComment={canComment}
            />,
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false
        }));
    };


    return (
        <tr className="tableRow">
            <td onClick={() => handleFolderClick(item)} className='cursorHover'>
                <i className={item.is_category ? "bi bi-folder-check icon-spacing" : "bi bi-folder icon-spacing"}></i>
                {item.name}
            </td>
            <td>{formatDate(item.created_at, t)}</td>
            <td>
                <FolderActions
                    item={item}
                    handleComments={handleComments}
                    setModalProperties={setModalProperties}
                    deleteFolder={deleteFolder}
                    setCurrentFolder={setCurrentFolder}
                    updateFolder={updateFolder}
                    refetch={refetch}
                    closeModal={closeModal}
                />
            </td>
        </tr>
    );
};

export default FolderRow;