import React, {useState, useEffect} from 'react';
import './i18n';
import Platform from './dataroom/Platform';
import Overview from './Overview';
import Controlpoints from './dataroom/controlpoints/Controlpoints';
import Workflow from './dataroom/workflow/Workflow';
import ClientRequests from './dataroom/client_requests/client_requests';
import Activities from './dataroom/activities/Activities';
import Documents from './dataroom/documents/Documents';
import IRL from './dataroom/irl/IRL';
import Assistant from './dataroom/assistant/Assistant';
import Configuration from './dataroom/configuration/Configuration';
import Team from './dataroom/team/Team';
import Workspace from './dataroom/workspace/Workspace';
import Permissions from './dataroom/permissions/Permissions';
import Contact from './general/Contact';
import TeamCardWrapper from './dataroom/team/TeamCardWrapper';
import AdminDashboard from './admin/AdminDashboard';
import AdminLayout from './admin/AdminLayout';
import { UserProvider } from './general/UserProvider';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import DisplayDataRoom from './dataroom/DisplayDataRoom';
import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import '@aws-amplify/ui-react/styles.css';
import { TokenManager } from './services/api';

const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  Auth: {
      region: process.env.REACT_APP_AWS_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
};

Amplify.configure(amplifyConfig);
Auth.configure(amplifyConfig)

function App() {
  const [user, setUser] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateSessionId = () => {
    const newSessionId = uuidv4();
    localStorage.setItem('session_id', newSessionId);
    setSessionId(newSessionId);
  }

  useEffect(() => {
    const existingSessionId = localStorage.getItem('session_id');

    if (existingSessionId) {
      setSessionId(existingSessionId);
    } else {
      updateSessionId();
    }

    const listener = Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          setUser(data.payload.data);
          updateSessionId();
          TokenManager.clearCache(); // Force le rafraîchissement du token
          break;
        case 'signOut':
          setUser(null);
          localStorage.removeItem('session_id');
          setSessionId(null);
          TokenManager.clearCache();
          break;
        default:
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => {
        setUser(currentUser);
        setLoading(false);
      })
      .catch(() => {
        console.log("Not signed in");
        setLoading(false);
      });

    return () => Hub.remove('auth', listener);
  }, []);

  const handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        console.log("Successfully signed out");
        TokenManager.clearCache();
      })
      .catch(error => {
        console.error("Error signing out: ", error);
      });
  };

  if (loading) {
    return <div>Loading...</div>;  
  }

  return (
    <UserProvider user={user} signOut={handleSignOut}>
      <div className="d-flex flex-column vh-100">
        <Router>
          <Routes>
            <Route path='/' element={<Overview />} />
            <Route path='/dataroom' element={<Platform />} />
            <Route path="/dataroom/:id" element={<DisplayDataRoom />}>
              <Route index element={<Workspace />} /> 
              <Route path="workspace" element={<Workspace />}>
                <Route path="note/:noteId" element={<Workspace />} />
              </Route>
              <Route path="requests" element={<ClientRequests />} />
              <Route path="controlpoints" element={<Controlpoints />} />
              <Route path="workflow" element={<Workflow />} />
              <Route path="workflow/cycle/:cycleId" element={<Workflow />} />
              <Route path="workflow/cycle/:cycleId/control/:controlId" element={<Workflow />} />
              <Route path="activities" element={<Activities />} />
              <Route path="documents" element={<Documents />} />
              <Route path="documents/folder/:folderUuid" element={<Documents />} />
              <Route path="documents/file/:fileUuid" element={<Documents />} />
              <Route path="irl" element={<IRL />} />
              <Route path="irl/:irId" element={<IRL />} />
              <Route path="assistant" element={<Assistant sessionId={sessionId} updateSessionId={updateSessionId} />} />
              <Route path="configuration" element={<Configuration />} />
              <Route path="permissions" element={<Permissions />} />
              <Route path="team" element={<Team />} />
              <Route path="team/:userId" element={<TeamCardWrapper />} />
            </Route>
            <Route path='/contact' element={<Contact />} />

            <Route
              path="/admin/*"
              element={
                user && (user.username === "jcrisch" || user.username === "michelcenteno") ? (
                  <AdminLayout>
                    <Routes>
                      <Route index element={<AdminDashboard />} />
                    </Routes>
                  </AdminLayout>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
          </Routes>
        </Router>
      </div>
    </UserProvider>
  );
}

export default withAuthenticator(App, { hideSignUp: true });