import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

const SummaryModal = ({ show, onHide, summary }) => {
  const { t } = useTranslation();
  if (!summary) return null;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      centered
      className="dm-modal"
      dialogClassName="dm-modal-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title className="summary-modal-title">
          <i className="bi bi-file-text me-2"></i>
          {t("pages.workflow.documentSummary")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="summary-modal-body">
        <div className="markdown-content">
          <ReactMarkdown>{summary}</ReactMarkdown>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SummaryModal;