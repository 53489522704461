// SearchBar.jsx
import React from 'react';
import { InputGroup, Form, Button, Spinner } from 'react-bootstrap';

const SearchBar = ({ searchQuery, onSearchChange, selectedCount, onLinkClick, isProcessing }) => {
  return (
    <div className="d-flex gap-3 align-items-start">
      <InputGroup>
        <InputGroup.Text>
          <i className="bi bi-search" />
        </InputGroup.Text>
        <Form.Control
          placeholder="Search in suggestions..."
          value={searchQuery}
          onChange={onSearchChange}
        />
      </InputGroup>

      <Button 
        variant="primary"
        onClick={onLinkClick}
        disabled={selectedCount === 0 || isProcessing}
        className="d-flex align-items-center gap-1 text-nowrap"
      >
        {isProcessing ? (
          <>
            <Spinner animation="border" size="sm" className="me-2" />
            Linking...
          </>
        ) : (
          <>
            <i className="bi bi-link-45deg" />
            Link selected ({selectedCount})
          </>
        )}
      </Button>
    </div>
  );
};

export default SearchBar;