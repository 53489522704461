import React, { useState } from 'react';
import LayoutPageHeader from '../../general/LayoutPageHeader';
import RequestsSubMenu from './components/RequestsSubMenu';
import DocumentRequestList from './documents/DocumentRequestList';
import QuestionRequestList from './questions/QuestionRequestList';

function ClientRequests() {
    const [activeTab, setActiveTab] = useState('documents');

    const renderContent = () => {
        switch (activeTab) {
            case 'documents':
                return <DocumentRequestList />;
            case 'questions':
                return <QuestionRequestList />;
            default:
                return null;
        }
    };

    return (
        <LayoutPageHeader title="Information Requests">
            <RequestsSubMenu activeTab={activeTab} onTabChange={setActiveTab} />
            {renderContent()}
        </LayoutPageHeader>
    );
}

export default ClientRequests;