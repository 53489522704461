import React from 'react';
import { useTranslation } from 'react-i18next';
import './NoteInformation.css';

import DeleteNote from '../DeleteNote';
import { useUser } from '../../../general/UserProvider';
import { formatDate } from '../../../utils/Date';

function NoteInformation({ info, commentCount, closeModal, deleteNote }) {
    const { t } = useTranslation();
    const { username } = useUser();
    const canDelete = info.permissions.Delete;
    const showDelete = canDelete || username === info.created_by;

    return (
        <div className="note-information">
            <table className="note-information-table">
                <tbody>
                    <tr>
                        <th>{t("general.item")}</th>
                        <th>{t("general.information")}</th>
                    </tr>
                    <tr>
                        <td><strong>{t("general.creationDate")}:</strong></td>
                        <td><i>{formatDate(info.created_at, t)}</i></td>
                    </tr>
                    <tr>
                        <td><strong>{t("general.lastEdited")}:</strong></td>
                        <td><i>{formatDate(info.updated_at, t)}</i></td>
                    </tr>
                    <tr>
                        <td><strong>{t("general.author")}:</strong></td>
                        <td><i>{info.created_by}</i></td>
                    </tr>
                    <tr>
                        <td><strong>{t("comment.title")}:</strong></td>
                        <td><i>{commentCount} {t('comment.title')}</i></td>
                    </tr>
                    <tr>
                        <td><strong>{t("general.tags")}:</strong></td>
                        <td><i>{Array.isArray(info.tags) ? info.tags.join(', ') : ''}</i></td>
                    </tr>
                </tbody>
            </table>
            {showDelete && (
                <DeleteNote note_id={info?.note_id} closeModal={closeModal} deleteNote={deleteNote} />
            )}
        </div>
    );
}
export default NoteInformation;
