import React from 'react';
import { Table, Form, Badge } from 'react-bootstrap';

const DOCUMENT_STATUS = {
   created: { variant: 'secondary', label: 'Created' },
   sent: { variant: 'primary', label: 'Sent' },
   completed: { variant: 'success', label: 'Completed' },
   rejected: { variant: 'danger', label: 'Rejected' }
};

const DocumentRequestTable = ({
   requests,
   selectedRequests,
   onSelectionChange,
   filter,
   onRowClick
}) => {
   const filteredRequests = requests.filter(req => 
       req.control.toLowerCase().includes(filter.toLowerCase()) ||
       req.document_type.toLowerCase().includes(filter.toLowerCase()) ||
       req.created_by.name.toLowerCase().includes(filter.toLowerCase())
   );

   const handleSelectAll = (e) => {
       if (e.target.checked) {
           onSelectionChange(new Set(filteredRequests.map(req => req.id)));
       } else {
           onSelectionChange(new Set());
       }
   };

   const handleSelectOne = (id) => {
       const newSelected = new Set(selectedRequests);
       if (newSelected.has(id)) {
           newSelected.delete(id);
       } else {
           newSelected.add(id);
       }
       onSelectionChange(newSelected);
   };

   const getStatusBadge = (status) => {
       const statusConfig = DOCUMENT_STATUS[status] || { variant: 'secondary', label: status };
       return <Badge bg={statusConfig.variant}>{statusConfig.label}</Badge>;
   };

   const formatDate = (dateString) => {
       return dateString ? new Date(dateString).toLocaleDateString() : '-';
   };

   return (
       <Table hover>
           <thead>
               <tr>
                   <th>
                       <Form.Check
                           type="checkbox"
                           onChange={handleSelectAll}
                           checked={selectedRequests.size === filteredRequests.length}
                       />
                   </th>
                   <th>Title</th>
                   <th>Control</th>
                   <th>Type</th>
                   <th>Status</th>
                   <th>Created by</th>
                   <th>Created at</th>
               </tr>
           </thead>
           <tbody>
               {filteredRequests.map(request => (
                   <tr 
                       key={request.id}
                       onClick={() => onRowClick?.(request)}
                       style={{ cursor: 'pointer' }}
                   >
                       <td onClick={e => e.stopPropagation()}>
                           <Form.Check
                               type="checkbox"
                               checked={selectedRequests.has(request.id)}
                               onChange={() => handleSelectOne(request.id)}
                           />
                       </td>
                       <td>
                           <div className="text-truncate" style={{ maxWidth: '200px' }}>
                               {request.title}
                           </div>
                       </td>
                       <td>
                           <div className="text-truncate" style={{ maxWidth: '200px' }}>
                               {request.control}
                           </div>
                       </td>
                       <td>{request.document_type}</td>
                       <td>{getStatusBadge(request.status)}</td>
                       <td>{request.created_by.name}</td>
                       <td>{formatDate(request.creation_date)}</td>
                   </tr>
               ))}
           </tbody>
       </Table>
   );
};

export default DocumentRequestTable;