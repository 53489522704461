import React from 'react';

const SuggestionsContainer = ({ children, isEmpty }) => {
  return (
    <div className="border rounded d-flex" style={{ overflowY: 'auto', minHeight: "200px" }}>
      {isEmpty ? (
        <div className="p-3 text-center text-muted flex-grow-1">
          No matching documents found
        </div>
      ) : children}
    </div>
  );
};

export default SuggestionsContainer;