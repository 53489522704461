import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DocumentItem from './DocumentItem';
import DocumentSection from './DocumentSection';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const RequestedDocuments = ({
    documents,
    onUploadDocument,
    onDeleteRequest,
    processingRequests,
    onAddClick,
    controlId,
    onSendReference
}) => {
    const { t } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [requestToDelete, setRequestToDelete] = useState(null);

    const handleRequestAll = () => {
        documents.forEach(reference => {
            if (!reference.is_requested) {
                onSendReference(controlId, reference.id);
            }
        });
    };

    const handleDeleteClick = (reference) => {
        setRequestToDelete(reference);
        setShowDeleteModal(true);
    };

    const handleDeleteFromControl = () => {
        onDeleteRequest(controlId, requestToDelete.id, false);
        setShowDeleteModal(false);
        setRequestToDelete(null);
    };
    
    const handleDeleteCompletely = () => {
        onDeleteRequest(controlId, requestToDelete.id, true);
        setShowDeleteModal(false);
        setRequestToDelete(null);
    };

    const Actions = (
        <div className="ms-auto d-flex gap-2">
            <Button 
                variant="light"
                size="sm"
                onClick={handleRequestAll}
                className="dm-add-btn"
                title="Request all"
            >
                <i className="bi bi-send"></i>
            </Button>
            <Button 
                variant="light"
                size="sm"
                onClick={onAddClick}
                className="dm-add-btn"
            >
                <i className="bi bi-plus-lg"></i>
            </Button>
        </div>
    );

    const handleUpload = (reference) => {
        const documentInfo = {
            name: reference.reference_name || reference.reference_description,
            description: reference.suggested_folder 
                ? `${reference.suggested_folder.name} / ${reference.reference_description}`
                : reference.reference_description,
            id: reference.id,
            requestedDocumentId: reference.id,
            folder: reference.suggested_folder_id || -1,
        };
        
        onUploadDocument(documentInfo);
    };

    const handleSendReference = (e, reference) => {
        e.stopPropagation();
        if (!reference.is_requested) {
            onSendReference(controlId, reference.id);
        }
    };

    return (
        <DocumentSection title={t("pages.workflow.requestedDocuments")} action={Actions}>
            {processingRequests.size > 0 && (
                <div className="dm-processing">
                    <Spinner 
                        animation="border" 
                        size="sm" 
                        className="me-2"
                    />
                    <span>{t("pages.workflow.aiIsAnalyzingYourCriteria")}</span>
                </div>
            )}

            {documents.length > 0 ? (
                <div className="dm-list">
                    {documents.map((reference) => (
                        <DocumentItem
                            key={reference.id}
                            name={reference.reference_name || reference.reference_description}
                            description={reference.reference_description}
                            document_type_name={
                                <>
                                    {reference.document_type_name}
                                    <span className="text-muted ms-2">
                                        {reference.is_requested ? (
                                            <>
                                                <i className="bi bi-check-circle text-success"></i> Requested
                                            </>
                                        ) : (
                                            <>
                                                <i className="bi bi-exclamation-circle text-warning"></i> Not requested
                                            </>
                                        )}
                                    </span>
                                </>
                            }
                            icon="bi-file-earmark"
                            metadata={<></>}
                            actions={
                                <>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                {reference.is_requested ? t("general.requestSent") : t("general.submitRequest")}
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            <i 
                                                className={`bi bi-send dm-action-btn ${reference.is_requested ? 'dm-action-btn--done' : 'dm-action-btn--request'}`}
                                                role={reference.is_requested ? 'status' : 'button'}
                                                onClick={(e) => handleSendReference(e, reference)}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{t("general.uploadDocument")}</Tooltip>}
                                    >
                                        <i 
                                            className="bi bi-cloud-upload dm-action-btn dm-action-btn--send"
                                            onClick={() => handleUpload(reference)}
                                        />
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{t("general.deleteRequest")}</Tooltip>}
                                    >
                                        <i 
                                            className="bi bi-trash dm-action-btn dm-action-btn--delete"
                                            onClick={() => handleDeleteClick(reference)}
                                        />
                                    </OverlayTrigger>
                                </>
                            }
                        />
                    ))}
                </div>
            ) : (
                <p className="dm-empty">{t("pages.workflow.noDocumentRequests")}</p>
            )}

            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => {
                    setShowDeleteModal(false);
                    setRequestToDelete(null);
                }}
                fileName={requestToDelete?.reference_name || requestToDelete?.reference_description}
                onDeleteFromControl={handleDeleteFromControl}
                onDeleteCompletely={handleDeleteCompletely}
            />
        </DocumentSection>
    );
};

export default RequestedDocuments;