import React from 'react';
import { ListGroup, Form } from 'react-bootstrap';

const SuggestionsList = ({ suggestions, selectedDocs, onDocumentClick }) => {
  console.log(suggestions);
  return (
    <ListGroup variant="flush" className="w-100">
      {suggestions.map((doc) => (
        <ListGroup.Item 
          key={doc.pieceRef}
          action
          onClick={(e) => onDocumentClick(doc, e)}
          className={`py-2 ${selectedDocs.has(doc.pieceRef) ? 'bg-light' : ''}`}
        >
          <div className="d-flex align-items-start gap-3 px-3">
            <div style={{ width: '40px', paddingTop: '4px' }}>
              <Form.Check
                type="checkbox"
                checked={selectedDocs.has(doc.pieceRef)}
                onChange={() => {}}
                onClick={(e) => e.stopPropagation()}
              />
            </div>

            <div style={{ width: '35%' }}>
              <div className="fw-semibold">{doc.pieceRef}</div>
              <div className="text-muted small">{doc.description}</div>
            </div>

            <div style={{ width: '25%' }}>
              <div className="text-muted">
                <i className="bi bi-tag me-2" />
                {doc.documentType}
              </div>
            </div>

            {doc.partnerName && (
              <div style={{ width: '25%' }}>
                <div className="text-muted small">
                  <i className="bi bi-building me-2" />
                  {doc.partnerName}
                </div>
              </div>
            )}
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default SuggestionsList;