import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, ListGroup, Badge, ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useCreateFile } from '../hooks/documents/useCreateFile';

import './FileUploadModal.css'

function FileUploadModal({ show, onHide, token, dataroomId, currentFolderId, refetchFiles }) {
    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const { createFile } = useCreateFile(token, dataroomId);
    const inputRef = useRef(null);

    const onDrop = useCallback(acceptedFiles => {
        const newFiles = acceptedFiles.map(file => ({
            file,
            preview: URL.createObjectURL(file),
            progress: 0,
            status: 'pending'
        }));
        setFiles(curr => [...curr, ...newFiles]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'text/plain': ['.txt'],
            'application/pdf': ['.pdf'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
        onDrop
    });

    const uploadFiles = async () => {
        const uploadableFiles = files.filter(file => !file.uploaded && !file.error);

        const uploadPromises = uploadableFiles.map(async (file) => {
            try {
                setUploadProgress(prev => ({ ...prev, [file.file.name]: { progress: 0, stage: 'Uploading' } }));

                setFiles(currentFiles => currentFiles.map(f => 
                    f.file.name === file.file.name ? { ...f, status: 'processing' } : f
                ));

                const result = await createFile(file.file, currentFolderId, (progress, stage) => {
                    setUploadProgress(prev => ({ ...prev, [file.file.name]: { progress, stage } }));
                });

                return { ...file, status: 'success', uploaded: true, response: result };
            } catch (error) {
                console.error('Error uploading or processing file:', file.file.name, error);
                setUploadProgress(prev => ({ ...prev, [file.file.name]: { progress: 100, stage: 'Error' } }));
                return { ...file, status: 'error', error: true };
            }
        });

        try {
            const results = await Promise.all(uploadPromises);
            setFiles(currentFiles => currentFiles.map(file => {
                const result = results.find(r => r.file.name === file.file.name);
                return result || file;
            }));
            refetchFiles();
        } catch (error) {
            console.error('Error during file uploads:', error);
        }
    };

    const handleDeleteFile = useCallback((fileIndex) => {
        const file = files[fileIndex];
        URL.revokeObjectURL(file.preview);
    
        setFiles(currentFiles => currentFiles.filter((_, index) => index !== fileIndex));
        setUploadProgress(prev => {
            const newProgress = { ...prev };
            delete newProgress[file.file.name];
            return newProgress;
        });
    }, [files]);

    return (
        <Modal show={show} onHide={() => {
            setFiles([]);
            setUploadProgress({});
            onHide();
        }} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('pages.documents.addFiles')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} ref={inputRef} />
                    <p>{t('pages.documents.dragAndDropFilesHere')}</p>
                    <p>{t('pages.documents.acceptedFormats')} .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx and .txt.</p>
                </div>
                <ListGroup>
                    {files.map((file, index) => (
                        <ListGroup.Item key={index} className="d-flex flex-column align-items-start file-item mt-2">
                            <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                                <div className="file-info">
                                    <span className="file-name">{file.file.name}</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="file-status mr-2">
                                        {file.status === 'pending' && <Badge bg="secondary">Ready</Badge>}
                                        {file.status === 'processing' && <Badge bg="primary">Processing</Badge>}
                                        {file.status === 'success' && <Badge bg="success">Processed</Badge>}
                                        {file.status === 'error' && <Badge bg="danger">Error</Badge>}
                                    </div>
                                    <span className="delete-btn" onClick={() => handleDeleteFile(index)}>
                                        <i className="bi bi-x-lg" style={{ cursor: 'pointer' }}></i>
                                    </span>
                                </div>
                            </div>
                            {uploadProgress[file.file.name] && (
                                <div className="w-100">
                                    <ProgressBar 
                                        now={uploadProgress[file.file.name].progress} 
                                        label={`${uploadProgress[file.file.name].stage} (${uploadProgress[file.file.name].progress}%)`}
                                        variant={uploadProgress[file.file.name].progress === 100 ? "success" : "primary"}
                                        striped={uploadProgress[file.file.name].progress !== 100}
                                        animated={uploadProgress[file.file.name].progress !== 100}
                                    />
                                </div>
                            )}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <Button variant="dark" className="mt-3" onClick={() => inputRef.current.click()}>
                    {t('pages.documents.addMoreFiles')}
                </Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>{t('general.close')}</Button>
                <Button variant="primary" 
                    onClick={uploadFiles} 
                    disabled={
                        files.length === 0 || 
                        files.every(file => file.uploaded || file.error)
                    }
                >
                    {t('pages.documents.uploadFiles')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default FileUploadModal;