import moment from 'moment-timezone';

// Fonction utilitaire sans dépendance à useTranslation
export const formatDate = (dateStr, t) => {
    const dateUtc = moment.tz(dateStr, "UTC");
    const diff = moment().diff(dateUtc, 'seconds');

    if (diff < 60) return t('dates.timeAgo.justNow');
    if (diff < 3600) return t('dates.timeAgo.minutes', { count: Math.floor(diff / 60) });
    if (diff < 86400) return t('dates.timeAgo.hours', { count: Math.floor(diff / 3600) });
    if (diff < 604800) return t('dates.timeAgo.days', { count: Math.floor(diff / 86400) });
    if (diff < 2592000) return t('dates.timeAgo.weeks', { count: Math.floor(diff / 604800) });
    if (diff < 31536000) return t('dates.timeAgo.months', { count: Math.floor(diff / 2592000) });
    return t('dates.timeAgo.years', { count: Math.floor(diff / 31536000) });
};

export const getStartOfDay = (date = new Date()) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
};

export const getOneWeekAgo = (fromDate = new Date()) => {
    const newDate = new Date(fromDate);
    newDate.setDate(newDate.getDate() - 7);
    return newDate;
};