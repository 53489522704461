import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import AIDocumentSuggestions from './AIDocumentSuggestions';
import ExistingDocumentsList from './ExistingDocumentsList';
import NewRequestForm from './NewRequestForm';

const RequestModal = ({
  show,
  onHide,
  existingDocuments = [],
  loading,
  createReference,
  extractReferences,
  linkMultipleDocuments,
  createAndLinkMixedReferences,
  onFileOpen
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('existing');
  const [isProcessing, setIsProcessing] = useState(false);

  // Handle existing documents linking
  const handleDocumentLink = async (selectedDocuments) => {
    if (typeof linkMultipleDocuments === 'function' && selectedDocuments.length > 0) {
      try {
        setIsProcessing(true);
        const documentIds = selectedDocuments.map(doc => doc.id);
        await linkMultipleDocuments(documentIds);
        onHide();
      } catch (error) {
        console.error('Error linking documents:', error);
      } finally {
        setIsProcessing(false);
      }
    }
  };

  // Handle direct request submission
  const handleDirectRequest = async (formData) => {
    setIsProcessing(true);
    try {
      await createReference(formData.title);
      onHide();
    } catch (error) {
      console.error('Error creating request:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  // Handle AI suggestions analysis
  const handleAIAnalysis = async (criteria) => {
    setIsProcessing(true);
    try {
      const result = await extractReferences(criteria);
      return result;
    } catch (error) {
      console.error('Error processing AI analysis:', error);
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  // Handle AI suggestions selection
  const handleAISuggestionsSelect = async (selectedSuggestions) => {
    try {
      setIsProcessing(true);

      // Pour l'instant, toutes les suggestions sont des nouvelles références
      const newReferences = selectedSuggestions.map(suggestion => ({
        reference_name: suggestion.pieceRef,
        reference_description: suggestion.description
      }));

      await createAndLinkMixedReferences({
        newReferences,
        existingReferenceIds: [] // Pour l'instant on n'a pas de références existantes
      });

      onHide();
    } catch (error) {
      console.error('Error processing suggestions:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      className="dm-modal large-modal"
      dialogClassName="dm-modal-dialog"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('pages.workflow.documentSection.requests.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
          className="mb-4"
        >
          <Tab
            eventKey="existing"
            title={
              <span>
                <i className="bi bi-link-45deg me-2" />
                {t('pages.workflow.documentSection.requests.existingDocuments')}
              </span>
            }
          >
            <ExistingDocumentsList
              documents={existingDocuments}
              onSelect={handleDocumentLink}
              loading={loading || isProcessing}
              onFileOpen={onFileOpen}
            />
          </Tab>

          <Tab
            eventKey="new"
            title={
              <span>
                <i className="bi bi-file-earmark-plus me-2" />
                {t('pages.workflow.documentSection.requests.newRequest')}
              </span>
            }
          >
            <NewRequestForm 
              onSubmit={handleDirectRequest}
              isSubmitting={isProcessing}
            />
          </Tab>

          <Tab
            eventKey="ai"
            title={
              <span>
                <i className="bi bi-robot me-2" />
                {t('pages.workflow.documentSection.requests.aiAssistant')}
              </span>
            }
          >
            <AIDocumentSuggestions
              onRequest={handleAIAnalysis}
              onSelect={handleAISuggestionsSelect}
              isProcessing={isProcessing}
            />
          </Tab>
        </Tabs>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          {t('general.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequestModal;