import axios from 'axios';
import { Auth } from 'aws-amplify';
import { fetchToken } from './auth';

export class TokenManager {
    static cachedToken = null;
    static tokenPromise = null;
    static lastFetch = null;
    static TOKEN_EXPIRY = 55 * 60 * 1000; // 55 minutes en millisecondes

    static async getToken() {
        const now = Date.now();

        // Si un token est en cours de récupération, on attend celui-là
        if (this.tokenPromise) {
            return this.tokenPromise;
        }

        // Si on a un token en cache et qu'il n'est pas expiré
        if (this.cachedToken && this.lastFetch && (now - this.lastFetch) < this.TOKEN_EXPIRY) {
            return this.cachedToken;
        }

        // Sinon on récupère un nouveau token
        console.log('Fetching new token...');
        this.tokenPromise = Auth.currentSession()
            .then(session => {
                this.cachedToken = session.getIdToken().getJwtToken();
                this.lastFetch = now;
                this.tokenPromise = null;
                return this.cachedToken;
            })
            .catch(error => {
                this.tokenPromise = null;
                throw error;
            });

        return this.tokenPromise;
    }

    static clearCache() {
        this.cachedToken = null;
        this.lastFetch = null;
    }
}

class RequestMonitor {
    static requests = new Map();

    static startRequest(url) {
        const requestId = `${url}-${Date.now()}`;
        this.requests.set(requestId, {
            url,
            startTime: Date.now(),
            tokenFetchTime: 0,
            status: 'pending'
        });
        return requestId;
    }

    static updateTokenTime(requestId, time) {
        const request = this.requests.get(requestId);
        if (request) {
            request.tokenFetchTime = time;
        }
    }

    static endRequest(requestId, success = true) {
        const request = this.requests.get(requestId);
        if (request) {
            request.endTime = Date.now();
            request.totalDuration = request.endTime - request.startTime;
            request.requestDuration = request.totalDuration - request.tokenFetchTime;
            request.status = success ? 'completed' : 'failed';

            console.table({
                url: request.url,
                'total duration': `${request.totalDuration}ms`,
                'token fetch': `${request.tokenFetchTime}ms`,
                'request time': `${request.requestDuration}ms`,
                status: request.status
            });

            this.requests.delete(requestId);
        }
    }
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(async (config) => {
    const requestId = RequestMonitor.startRequest(config.url);
    config.requestId = requestId;

    const tokenStart = Date.now();
    try {
        const token = await TokenManager.getToken();
        const tokenTime = Date.now() - tokenStart;
        RequestMonitor.updateTokenTime(requestId, tokenTime);
        
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    } catch (error) {
        RequestMonitor.endRequest(requestId, false);
        throw error;
    }
}, (error) => {
    console.error(`Request error: ${error}`);
    return Promise.reject(error);
});

api.interceptors.response.use((response) => {
    RequestMonitor.endRequest(response.config.requestId, true);
    return response;
}, async (error) => {
    console.error(`Response error from ${error.config?.url}: ${error}`);
    
    if (error.response && error.response.status === 401) {
        TokenManager.clearCache();
        await fetchToken();
        const token = await TokenManager.getToken();
        error.config.headers.Authorization = `Bearer ${token}`;
        return api.request(error.config);
    }

    if (error.config?.requestId) {
        RequestMonitor.endRequest(error.config.requestId, false);
    }
    return Promise.reject(error);
});

export default api;