import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

const ControlSteps = ({ steps, onStepChange, onAddStep, onRemoveStep }) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label>Steps</Form.Label>
      {steps.map((step, index) => (
        <Row key={index} className="mb-2">
          <Col>
            <Form.Control
              value={step}
              onChange={(e) => onStepChange(index, e.target.value)}
              placeholder={`Step ${index + 1}`}
              required
            />
          </Col>
          <Col xs="auto">
            <Button variant="outline-danger" onClick={() => onRemoveStep(index)}>
              <i className="bi bi-trash"></i>
            </Button>
          </Col>
        </Row>
      ))}
      <Button variant="outline-primary" onClick={onAddStep} className="mt-2">
        Add Step
      </Button>
    </Form.Group>
  );
};

export default ControlSteps;