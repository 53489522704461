import React, { useState } from 'react';
import { Form, ListGroup, Alert, Button, Modal, Row, Col, Spinner } from 'react-bootstrap';

const ControlRequiredDocuments = ({ 
  allRequiredDocuments = [], 
  selectedDocuments = [], 
  documentTypes = [], 
  loading,
  onToggleDocument,
  onAddDocument,
  onDeleteDocument,
  isAdding = false,
  isDeleting = false,
  error
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [newDocument, setNewDocument] = useState({
    document_type_id: '',
    fiscal_year: 'N',
    description: ''
  });

  const handleSubmitNewDocument = () => {
    onAddDocument(newDocument);
    setNewDocument({ document_type_id: '', fiscal_year: 'N', description: '' });
    setShowAddModal(false);
  };

  const handleDeleteClick = (doc) => {
    setDocumentToDelete(doc);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (documentToDelete) {
      onDeleteDocument(documentToDelete.id);
      setShowDeleteModal(false);
      setDocumentToDelete(null);
    }
  };

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Form.Group className="mb-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <Form.Label className="mb-0">Required Documents</Form.Label>
        <Button 
          variant="outline-primary"
          size="sm"
          onClick={() => setShowAddModal(true)}
          disabled={isAdding}
        >
          {isAdding ? (
            <Spinner size="sm" animation="border" />
          ) : (
            <i className="bi bi-plus-lg"></i>
          )}
        </Button>
      </div>

      {loading ? (
        <Alert variant="info">Loading documents...</Alert>
      ) : (
        <ListGroup className="mt-2">
          {allRequiredDocuments.map(doc => (
            <ListGroup.Item 
              key={doc.id}
              active={selectedDocuments.includes(doc.id.toString())}
              className="d-flex align-items-center"
            >
              <div 
                className="flex-grow-1 d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                onClick={() => onToggleDocument(doc.id)}
              >
                <div className="me-2">
                  {selectedDocuments.includes(doc.id.toString()) ? (
                    <i className="bi bi-check-circle-fill text-success"></i>
                  ) : (
                    <i className="bi bi-circle text-muted"></i>
                  )}
                </div>
                <div className="flex-grow-1">
                  <div className="fw-medium">
                    {doc.document_type?.name}
                    <span className="ms-2 badge bg-secondary">
                      Year: {doc.fiscal_year}
                    </span>
                  </div>
                  <div className="text-muted small mt-1">
                    <div>{doc.document_type?.description}</div>
                    {doc.description && (
                      <div className="mt-1">Note: {doc.description}</div>
                    )}
                  </div>
                </div>
              </div>
              <Button 
                variant="link" 
                className="text-danger p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(doc);
                }}
                disabled={isDeleting && documentToDelete?.id === doc.id}
              >
                {isDeleting && documentToDelete?.id === doc.id ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  <i className="bi bi-trash"></i>
                )}
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}

      {/* Add Document Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Required Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-3">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Document Type</Form.Label>
                <Form.Select
                  value={newDocument.document_type_id}
                  onChange={(e) => setNewDocument(prev => ({
                    ...prev,
                    document_type_id: e.target.value
                  }))}
                >
                  <option value="">Select document type</option>
                  {documentTypes.map(type => (
                    <option key={type.id} value={type.id}>{type.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Fiscal Year</Form.Label>
                <Form.Control
                  placeholder="N, N-1, etc."
                  value={newDocument.fiscal_year}
                  onChange={(e) => setNewDocument(prev => ({
                    ...prev,
                    fiscal_year: e.target.value
                  }))}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Description (optional)</Form.Label>
                <Form.Control
                  placeholder="Add any additional notes..."
                  value={newDocument.description}
                  as="textarea"
                  rows={3}
                  onChange={(e) => setNewDocument(prev => ({
                    ...prev,
                    description: e.target.value
                  }))}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>
          <Button 
            variant="primary"
            onClick={handleSubmitNewDocument}
            disabled={!newDocument.document_type_id || isAdding}
          >
            {isAdding && (
              <Spinner size="sm" animation="border" className="me-2" />
            )}
            Add Document
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the required document 
          <strong> {documentToDelete?.document_type?.name}</strong>
          {documentToDelete?.fiscal_year !== 'N' && ` (Year: ${documentToDelete?.fiscal_year})`}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button 
            variant="danger"
            onClick={handleConfirmDelete}
            disabled={isDeleting && documentToDelete}
          >
            {isDeleting && documentToDelete && (
              <Spinner size="sm" animation="border" className="me-2" />
            )}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Form.Group>
  );
};

export default ControlRequiredDocuments;