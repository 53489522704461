import React from 'react';
import { useTranslation } from 'react-i18next';

function TableHeader({ sortOrder, setSortOrder, sortedColumn, setSortedColumn }) {
    const { t } = useTranslation();

    const handleHeaderClick = (column) => {
        if (sortedColumn === column) {
            setSortOrder(sortOrder === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortedColumn(column);
            setSortOrder('ascending');
        }
    };


    return (
        <thead>
            <tr>
                <th onClick={() => handleHeaderClick('name')} className='tableCellName cursor-pointer'>
                    {t('pages.documents.tableName')} {sortedColumn === 'name' && (sortOrder === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleHeaderClick('created_at')} className="cursor-pointer">
                    {t('general.createdAt')} {sortedColumn === 'created_at' && (sortOrder === 'ascending' ? '↑' : '↓')}
                </th>
                <th></th>
            </tr>
        </thead>
    );
};

export default TableHeader;