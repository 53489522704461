// workflow/overview/auditDocumentsConfig.js
import { useTranslation } from 'react-i18next';

export const baseDocumentDependencies = {
  balanceSheet: {
    requiredDocs: ['fec'],
    periodConstraints: "currentYear"
  },
  incomeStatement: {
    requiredDocs: ['fec'],
    periodConstraints: "currentAndPreviousYear"
  },
  subsidiaryLedgers: {
    requiredDocs: ['fec'],
    periodConstraints: "currentYear"
  }
};

export const useDocumentDependencies = () => {
  const { t } = useTranslation();
  
  return {
    balanceSheet: {
      ...baseDocumentDependencies.balanceSheet,
      description: t('pages.documents.dependencies.balanceSheet.description')
    },
    incomeStatement: {
      ...baseDocumentDependencies.incomeStatement,
      description: t('pages.documents.dependencies.incomeStatement.description')
    },
    subsidiaryLedgers: {
      ...baseDocumentDependencies.subsidiaryLedgers,
      description: t('pages.documents.dependencies.subsidiaryLedgers.description')
    }
  };
};
  
// Données de structure de base sans textes à traduire
export const baseDocumentCategories = [
  {
    key: 'auditPlanning',
    documents: [
      {
        key: 'auditPlan',
        folder: 18,
        canGenerate: false
      },
      {
        key: 'engagementLetter',
        folder: 18,
        canGenerate: false
      }
    ]
  },
  {
    key: 'financial',
    documents: [
      {
        key: 'fec',
        folder: 17,
        canGenerate: false
      },
      {
        key: 'balanceSheet',
        folder: 16,
        canGenerate: true
      },
      {
        key: 'subsidiaryLedgers',
        folder: 16,
        canGenerate: true
      },
      {
        key: 'incomeStatement',
        folder: 16,
        canGenerate: true
      }
    ]
  }
];

// Hook pour obtenir les catégories avec les traductions
export const useDocumentCategories = () => {
  const { t } = useTranslation();

  return baseDocumentCategories.map(category => ({
    title: t(`pages.documents.categories.${category.key}.title`),
    description: t(`pages.documents.categories.${category.key}.description`),
    documents: category.documents.map(doc => ({
      ...doc,
      name: t(`pages.documents.${doc.key}.name`),
      description: t(`pages.documents.${doc.key}.description`)
    }))
  }));
};