import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Spinner } from 'react-bootstrap';
import FolderSelector from '../../common/FolderSelector';
import { useIRFormData } from '../../hooks/ir/useIRFormData';
import { useIRFormValidation } from '../../hooks/ir/useIRFormValidation';
import { findFolderById } from '../../utils/Folders';

function NewRequestForm({ onSubmit, folders, users = [] }) {
    const { t } = useTranslation();
    const initialFormState = useMemo(() => ({
        title: '',
        description: '',
        deadline: '',
        folder_id: folders.length > 0 ? folders[0].id : '',
        folder_name: '',
        folder_hierarchy: [],
        assignee: ''
    }), [folders]);

    const [formData, handleInputChange, resetFormData] = useIRFormData(initialFormState);

    const validate = useCallback((formData) => {
        const errors = {};
        if (!formData.title.trim()) errors.title = t('pages.qa.titleRequired');
        if (!formData.description.trim()) errors.description = t('pages.qa.descriptionRequired');
        if (!formData.deadline.trim()) errors.deadline = t('pages.qa.deadlineRequired');
        return errors;
    }, [t]);
      
    const submitForm = useCallback(() => {
        const folderResult = findFolderById(folders, formData.folder_id);

        if (folderResult) {
            const selectedFolder = folderResult.selectedFolder;
            const folderHierarchy = folderResult.folderHierarchy;
            onSubmit({
                ...formData,
                folder_name: selectedFolder?.name,
                folder_hierarchy: folderHierarchy
            });
            resetFormData(initialFormState);
        } else {
            console.log("Folder not found");
        }
    }, [formData, onSubmit, resetFormData, initialFormState, folders]);

    const { handleSubmit, errors, isSubmitting } = useIRFormValidation(formData, validate, submitForm);

    const buttonContent = useMemo(() => {
        if (isSubmitting) {
            return (
                <>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                    />
                    {t('loading')}...
                </>
            );
        }
        return (
            <>
                <i className="bi bi-file-earmark-plus me-2"></i>
                {t('pages.qa.createInformationRequest')}
            </>
        );
    }, [isSubmitting, t]);

    return (
        <Form onSubmit={handleSubmit}>
            <FolderSelector 
                folders={folders} 
                value={formData.folder_id} 
                handleInputChange={handleInputChange} 
                displayLabel={true}
            />

            <Form.Group className="text-black pt-3">
                <Form.Label>{t('pages.qa.tableTitle')}</Form.Label>
                <Form.Control
                    type="text"
                    value={formData.title}
                    onChange={handleInputChange("title")}
                    name="title"
                    placeholder={t('pages.qa.titlePlaceholder')}
                />
                {errors.title && <Form.Text className="text-danger">{errors.title}</Form.Text>}
            </Form.Group>
            
            <Form.Group className="text-black pt-3">
                <Form.Label>{t('pages.qa.describeYourRequest')}</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={formData.description}
                    onChange={handleInputChange("description")} 
                    name="description" 
                    placeholder={t('pages.qa.describeYourRequestPlaceholder')}
                />
                {errors.description && <Form.Text className="text-danger">{errors.description}</Form.Text>}
            </Form.Group>

            <Form.Group className="text-black pt-3">
                <Form.Label>{t('pages.qa.tableDeadline')}</Form.Label>
                <Form.Control
                    type="date"
                    value={formData.deadline}
                    onChange={handleInputChange("deadline")}
                    name="deadline"
                />
                {errors.deadline && <Form.Text className="text-danger">{errors.deadline}</Form.Text>}
            </Form.Group>

            <Form.Group className="text-black pt-3">
                <Form.Label>{t('pages.qa.tableAssignee')}</Form.Label>
                <Form.Control 
                    as="select" 
                    value={formData.assignee || ''} 
                    onChange={handleInputChange("assignee")} 
                    name="assignee"
                >
                    <option value="">{t('pages.qa.noAssignee')}</option>
                    {users.map(user => (
                        <option key={user.user_id} value={user.user_id}>
                            {user.username}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>

            <Button 
                variant="dark" 
                type="submit" 
                className="mt-3" 
                disabled={Object.keys(errors).length > 0 || isSubmitting}
            >
                {buttonContent}
            </Button>
        </Form>
    );
}

export default NewRequestForm;