import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Dropdown, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Comment.css';
import { useUser } from '../../general/UserProvider';
import { useDeleteComment } from '../../hooks/comments/useDeleteComment';

function Comment({ commentId, user, user_img, user_url, date, comment, className, onDelete }) {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { username, token } = useUser();
    const { t } = useTranslation();
    const currentUser = username;
    const canDelete = currentUser && currentUser === user;

    const deleteComment = useDeleteComment(token);

    const handleDelete = () => {
        setShowConfirmModal(false);
        deleteComment(commentId, onDelete);
    };

    return (
        <div className={`comment ${className}`}>
            <div className="comment-header">
                <Link to={user_url}>
                    <Image src={user_img} roundedCircle alt={user} className="comment-avatar"/>
                    {user}
                </Link>
                <span className="comment-date">{date}</span>
                {canDelete && (
                    <Dropdown className="comment-actions">
                        <Dropdown.Toggle variant="link" size="sm" className="delete-btn">
                            <i className="bi bi-three-dots-vertical"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                            <Dropdown.Item onClick={() => setShowConfirmModal(true)} className="delete-item">
                                <i className="bi bi-trash me-2"></i>{t('general.delete')}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
            <p className="comment-body">{comment}</p>

            <Modal 
                show={showConfirmModal} 
                onHide={() => setShowConfirmModal(false)}
                centered
                className="comment-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('comment.confirmDeletion')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('comment.confirmDeletionMessage')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                        {t('general.cancel')}
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        {t('general.delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Comment;