import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuickPromptButtons from './QuickPromptButtons';
import './NewMessageInput.css';

const NewMessageInput = ({ onSubmit, isStreaming, disabled }) => {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim() && !isStreaming) {
            onSubmit(message);
            setMessage('');
        }
    };

    const handlePromptSelect = (prompt) => {
        setMessage(prompt);
    };

    return (
        <div className="chat-input-area">
            <QuickPromptButtons onPromptSelect={handlePromptSelect} />
            <form onSubmit={handleSubmit} className="message-form">
                <div className="input-container">
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder={t("pages.copilot.typeYourMessage")}
                        disabled={disabled || isStreaming}
                        className="message-textarea"
                        rows="3"
                    />
                    <button 
                        type="submit" 
                        disabled={!message.trim() || isStreaming || disabled}
                        className="send-button"
                    >
                        <i className="bi bi-send-fill"></i>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default NewMessageInput;