import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Badge } from 'react-bootstrap';
import { formatDate } from '../../utils/Date';
import EditableField from './EditableField';
import CommentSection from '../general/CommentSection';
import './IRDetails.css';

function IRDetails({ 
  ir, 
  folders = [], 
  statuses = [], 
  users = [], 
  onFieldUpdate,
  requests = [],
  setRequests 
}) {
  const { t } = useTranslation();
  const currentIr = useMemo(() => {
    return requests.find(r => r.irl_id === ir?.irl_id) || ir;
  }, [requests, ir]);

  const handleUpdate = useCallback(async (field, value) => {
    if (!currentIr) return;
    
    // Si c'est un status, on convertit l'ID en nom
    let processedValue = value;
    let displayValue = value;
    
    if (field === 'status') {
      const statusObject = statuses.find(s => s.status_id === parseInt(value, 10));
      if (!statusObject) {
        return;
      }
      processedValue = statusObject.name;
      displayValue = statusObject.name;
    }

    const updatedIr = {
      ...currentIr,
      [field]: displayValue,
      ...(field === 'folder_id' && {
        folder_name: folders.find(f => f.id === parseInt(value))?.name
      }),
      ...(field === 'assignee' && {
        assignee_name: users.find(u => u.user_id === value)?.username
      }),
      ...(field === 'status' && {
        status_id: parseInt(value, 10)
      })
    };


    setRequests(prevRequests => {
      const newRequests = prevRequests.map(request =>
        request.irl_id === currentIr.irl_id ? updatedIr : request
      );
      return newRequests;
    });

    await onFieldUpdate(currentIr, field, processedValue);
  }, [currentIr, onFieldUpdate, folders, users, setRequests, statuses]);

  const formattedFolders = useMemo(() => {
    const formatFolder = (folder, level = 0) => {
      const prefix = level > 0 ? '└─ ' : '';
      const padding = level > 0 ? '\u3000'.repeat(level) : '';

      let options = [{
        value: folder.id,
        label: `${padding}${prefix}${folder.name}`
      }];

      if (folder.subcategories && folder.subcategories.length > 0) {
        folder.subcategories.forEach(sub => {
          options = options.concat(formatFolder(sub, level + 1));
        });
      }

      return options;
    };

    return folders.reduce((acc, folder) => {
      return acc.concat(formatFolder(folder));
    }, []);
  }, [folders]);

  const displayedIr = currentIr || ir;

  function getStatusVariant(status) {
    switch (status) {
      case 'Completed':
        return "success";
      case 'Overdue':
      case 'Rejected':
        return "danger";
      default:
        return "primary";
    }
  }

  return (
    <div className="ir-details">
      <Row className="mb-3">
        <Col>
          <EditableField 
            value={displayedIr.title} 
            onSave={(value) => handleUpdate('title', value)}
          >
            <h2 className="ir-title">
              <i className="bi bi-file-text me-2"></i>
              {displayedIr.title}
            </h2>
          </EditableField>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <EditableField 
            value={displayedIr.status_id} 
            onSave={(value) => handleUpdate('status', value)}
            type="select"
            options={statuses.map(s => ({ 
              value: s.status_id.toString(), 
              label: s.name 
            }))}
          >
            <Badge bg={getStatusVariant(displayedIr.status)} className="status-badge">
              {displayedIr.status}
            </Badge>
          </EditableField>
        </Col>
      </Row>
      
      <Row className="mb-4">
        <Col xs={12}>
          <h6>{t('pages.qa.detailsDescription')}</h6>
          <EditableField 
            value={displayedIr.description} 
            onSave={(value) => handleUpdate('description', value)}
            type="textarea"
          >
            <p style={{ whiteSpace: 'pre-line' }}>{displayedIr.description}</p>
          </EditableField>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6}>
          <h6>{t('pages.qa.detailsRequestDetails')}</h6>
          <div className="detail-item">
            <i className="bi bi-folder icon"></i>
            <EditableField 
              value={displayedIr.folder_id} 
              onSave={(value) => handleUpdate('folder_id', value)}
              type="select"
              options={formattedFolders.map(f => ({
                value: f.value,
                label: f.label,
                disabled: f.isCategory
              }))}
            >
              <div>
                <small>{t('pages.qa.tableFolder')}</small>
                <p>{displayedIr.folder_name}</p>
              </div>
            </EditableField>
          </div>
          <div className="detail-item">
            <i className="bi bi-calendar-event icon"></i>
            <EditableField 
              value={displayedIr.deadline} 
              onSave={(value) => handleUpdate('deadline', value)}
              type="date"
            >
              <div>
                <small>{t('pages.qa.tableDeadline')}</small>
                <p>{formatDate(displayedIr.deadline, t)}</p>
              </div>
            </EditableField>
          </div>
        </Col>
        <Col md={6}>
          <h6>{t('pages.qa.detailsPeople')}</h6>
          <div className="detail-item">
            <i className="bi bi-person-circle icon"></i>
            <EditableField 
              value={displayedIr.assignee} 
              onSave={(value) => handleUpdate('assignee', value)}
              type="select"
              options={[
                { value: '', label: 'Unassigned' },
                ...users.map(u => ({ value: u.user_id, label: u.username }))
              ]}
            >
              <div>
                <small>{t('pages.qa.tableAssignee')}</small>
                <p>{displayedIr.assignee_name || 'Unassigned'}</p>
              </div>
            </EditableField>
          </div>
          <div className="detail-item">
            <i className="bi bi-person-plus icon"></i>
            <div>
              <small>{t('general.createdBy')}</small>
              <p>{displayedIr.creator}</p>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <h6>Dates</h6>
        </Col>
        <Col xs={6}>
          <div className="detail-item">
            <i className="bi bi-clock-history icon"></i>
            <div>
              <small>{t('general.createdAt')}</small>
              <p>{formatDate(displayedIr.createdAt, t)}</p>
            </div>
          </div>
        </Col>
        <Col xs={6}>
          <div className="detail-item">
            <i className="bi bi-clock icon"></i>
            <div>
              <small>{t('general.lastUpdateAt')}</small>
              <p>{formatDate(displayedIr.lastUpdate, t)}</p>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12}>
          <h6>{t('comment.title')}</h6>
          <CommentSection
            objectId={displayedIr.irl_id}
            objectType="IRL"
            canComment={true}
            onCommentChange={(count) => {
              console.log('Nombre de commentaires modifié:', count);
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default IRDetails;