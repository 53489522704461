import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { Tooltip, OverlayTrigger, Badge } from 'react-bootstrap';
import RowActions from './RowActions';
import { formatDate } from '../../../utils/Date';
import { TEAM_URL } from '../../../utils/urls';

function IRRow({
  item,
  dataroom_id,
  onRowClick,
  setModalState,  
  closeModal,     
  submitDelete    
}) {
    const { t } = useTranslation();
    const folderName = item.folder_name; 
    const folderHierarchyTooltip = (item.folder_hierarchy.length > 0 
        ? item.folder_hierarchy.join(' > ') + ' > '
        : '') + folderName;

    const renderTooltip = props => (
        <Tooltip {...props}>{folderHierarchyTooltip}</Tooltip>
    );

    function getStatusVariant(status) {
        switch (status) {
            case 'Completed':
                return "success";
            case 'Overdue':
            case 'Rejected':
                return "danger";
            case 'In Progress':
                return "primary";
            case 'Pending':
                return "warning";
            default:
                return "secondary";
        }
    }

    return (
        <tr className="irl-row">
            <td className="irl-cell folder-cell">
                <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip}
                >
                    <div className="folder-name">{folderName}</div>
                </OverlayTrigger>
            </td>
            <td className="irl-cell">
                <Link 
                    to="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        onRowClick(item);
                    }}
                    className="ir-title-link"
                    title="Click to see details"
                >
                    {item.title}
                </Link>
            </td>
            <td className="irl-cell text-center">
                <Badge bg={getStatusVariant(item.status)}>
                    {item.status}
                </Badge>
            </td>
            <td className="irl-cell text-center">
                <Link to={TEAM_URL.replace(':dataroomId', dataroom_id).replace(':teamId', item.created_by)}>
                    {item.creator}
                </Link>
            </td>
            <td className="irl-cell text-center">
                <Link to={TEAM_URL.replace(':dataroomId', dataroom_id).replace(':teamId', item.assignee)}>
                    {item.assignee_name}
                </Link>
            </td>
            <td className="irl-cell text-center">{formatDate(item.deadline, t)}</td>
            <td className="irl-cell">
                <RowActions
                    item={item}
                    setModalState={setModalState}
                    closeModal={closeModal}
                    submitDelete={submitDelete}
                />
            </td>
        </tr>
    );
}

export default IRRow;