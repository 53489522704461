import { useState, useCallback } from 'react';

export const useAISuggestions = (onRequest, onSelect) => {
  const [suggestions, setSuggestions] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (criteria) => {
    console.log('Submitting with criteria:', criteria);
    if (!criteria?.trim()) {
      console.log('Empty criteria, aborting');
      return;
    }

    setError(null);
    setSuggestions([]);
    setSelectedDocs(new Set());
    setShowSuggestions(false);
    
    try {
      console.log('Calling API with request:', { user_request: criteria });
      const response = await onRequest({
        user_request: criteria
      });
      console.log('Raw API Response:', response);

      // Si la réponse est déjà un tableau, utilisons-la directement
      const suggestions = Array.isArray(response) ? response : response.data;
      console.log('Processed suggestions:', suggestions);

      if (!Array.isArray(suggestions)) {
        console.error('Invalid suggestions format:', suggestions);
        throw new Error('Invalid response format from server');
      }

      setSuggestions(suggestions);
      // Pré-cocher toutes les suggestions
      setSelectedDocs(new Set(suggestions.map(doc => doc.pieceRef)));
      setShowSuggestions(true);
    } catch (err) {
      console.error('Error in handleSubmit:', {
        error: err,
        name: err.name,
        message: err.message,
        stack: err.stack
      });
      setError('An error occurred while analyzing');
      setSuggestions([]);
    }
  };

  const filteredSuggestions = useCallback(() => {
    if (!searchQuery) return suggestions;
    const query = searchQuery.toLowerCase();
    console.log('Filtering suggestions with query:', query);
    
    return suggestions.filter(doc => {
      try {
        const matches = (
          doc.pieceRef?.toLowerCase().includes(query) ||
          doc.description?.toLowerCase().includes(query) ||
          doc.documentType?.toLowerCase().includes(query) ||
          doc.partnerName?.toLowerCase().includes(query)
        );
        if (matches) {
          console.log('Matched document:', doc);
        }
        return matches;
      } catch (err) {
        console.error('Error filtering document:', { doc, error: err });
        return false;
      }
    });
  }, [suggestions, searchQuery]);

  const handleDocumentClick = useCallback((doc, event) => {
    event.preventDefault();
    console.log('Document clicked:', doc);
    
    if (!doc?.pieceRef) {
      console.warn('Document missing pieceRef:', doc);
      return;
    }

    setSelectedDocs(prev => {
      const newSelected = new Set(prev);
      if (newSelected.has(doc.pieceRef)) {
        console.log('Deselecting document:', doc.pieceRef);
        newSelected.delete(doc.pieceRef);
      } else {
        console.log('Selecting document:', doc.pieceRef);
        newSelected.add(doc.pieceRef);
      }
      return newSelected;
    });
  }, []);

  const handleLinkSelected = useCallback(() => {
    const selectedSuggestions = suggestions.filter(doc => selectedDocs.has(doc.pieceRef));
    console.log('Linking selected documents:', selectedSuggestions);
    
    if (selectedSuggestions.length > 0) {
      try {
        onSelect(selectedSuggestions);
      } catch (err) {
        console.error('Error in handleLinkSelected:', {
          error: err,
          selectedSuggestions
        });
        setError('An error occurred while processing selected documents');
      }
    }
  }, [suggestions, selectedDocs, onSelect]);

  return {
    suggestions: filteredSuggestions(),
    selectedDocs,
    searchQuery,
    showSuggestions,
    error,
    setSearchQuery,
    handleSubmit,
    handleDocumentClick,
    handleLinkSelected
  };
};