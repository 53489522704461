import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import RequestedDocuments from './RequestedDocuments';
import AssociatedDocuments from './AssociatedDocuments';
import { RequestModal } from './documentRequests';
import UploadSpecificDocumentModal from '../processes/UploadSpecificDocumentModal';
import { useControlDocuments } from '../../../hooks/workflow/useControlDocuments';
import './DocumentManager.css';

function DocumentManager({ 
    procedure, 
    onFileOpen, 
    dataroomId,
    token,
    onRequestedDocsChange
}) {
    const { t } = useTranslation();
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [requestType, setRequestType] = useState(null);
    const [criteria, setCriteria] = useState('');
    const [directRequest, setDirectRequest] = useState('');
    const [processingRequests, setProcessingRequests] = useState(new Set());
    const [initialLoading, setInitialLoading] = useState(true);
    const isMissingDocuments = procedure.status === "Missing Documents";
    
    const { 
        documentReferences, 
        loading: aiLoading, 
        availableDocumentsLoading,
        error,
        extractReferences,
        fetchReferences,
        deleteReference,
        sendReference,
        createReference,
        updateAttribute,
        availableDocuments,
        linkExistingDocument,
        linkMultipleDocuments,
        createAndLinkMixedReferences
    } = useControlDocuments(token, dataroomId, procedure.dataroom_control_id);

    // Handle initial loading
    useEffect(() => {
        if (documentReferences) {
            setInitialLoading(false);
        }
    }, [documentReferences]);

    const requestedDocs = useMemo(() => 
        documentReferences?.filter(ref => !ref.file_id) || [], 
        [documentReferences]
    );
    
    const associatedDocs = useMemo(() => 
        documentReferences?.filter(ref => ref.file_id) || [], 
        [documentReferences]
    );

    useEffect(() => {
        onRequestedDocsChange?.(requestedDocs.length);
    }, [requestedDocs, onRequestedDocsChange]);

    const handleLinkDocument = async (documentId) => {
        try {
            await linkExistingDocument(documentId);
        } catch (error) {
            console.error('Failed to link document:', error);
        }
    };

    const handleUploadDocument = useCallback(async (documentInfo) => {
        setSelectedDocument(documentInfo);
        setShowUploadModal(true);
    }, []);

    const handleSendReference = async (controlId, referenceId) => {
        try {
            await sendReference(controlId, referenceId);
        } catch (error) {   
            console.error('Failed to send reference:', error);
        }
    };

    const handleUploadComplete = async (response) => {
        await fetchReferences();
    };

    const handleAddFileRequest = useCallback(async (controlId, request) => {
        await fetchReferences();  
    }, [fetchReferences]);

    const handleRequestSubmit = async () => {
        if (requestType === 'ai') {
            const tempId = `temp-${Date.now()}`;
            setProcessingRequests(prev => new Set(prev).add(tempId));

            try {
                await extractReferences(criteria);
            } finally {
                setProcessingRequests(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(tempId);
                    return newSet;
                });
            }
        } else {
            await handleAddFileRequest(procedure.dataroom_control_id, {
                request_message: directRequest,
                is_request: true,
                ai_generated: false
            });
        }
        resetModal();
    };

    const handleDeleteRequest = async (controlId, referenceId, deleteCompletely = false) => {
        try {
            await deleteReference(controlId, referenceId, deleteCompletely);
            await fetchReferences();
        } catch (error) {
            console.error('Failed to delete reference:', error);
        }
    };

    const handleDeleteAssociated = async (controlId, referenceId, deleteCompletely = false) => {
        try {
            await deleteReference(controlId, referenceId, deleteCompletely);
        } catch (error) {
            console.error('Failed to delete reference:', error);
        }
    };

    const resetModal = () => {
        setShowRequestModal(false);
        setRequestType(null);
        setCriteria('');
        setDirectRequest('');
    };

    const handleUpdateAttribute = async (attributeId, newValue) => {
        try {
            await updateAttribute(dataroomId, attributeId, newValue);
            await fetchReferences();
        } catch (error) {
            console.error('Failed to update attribute:', error);
        }
    };

    if (initialLoading) {
        return (
            <div className="dm-container d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "200px" }}>
                <Spinner animation="border" />
                <div className="mt-2">{t("loading")}</div>
            </div>
        );
    }

    return (
        <div className="dm-container">
            {isMissingDocuments && (
                <RequestedDocuments
                    documents={requestedDocs}
                    onUploadDocument={handleUploadDocument}
                    onDeleteRequest={handleDeleteRequest}
                    processingRequests={processingRequests}
                    onAddClick={() => setShowRequestModal(true)}
                    controlId={procedure.dataroom_control_id}
                    onSendReference={handleSendReference}
                />
            )}
            
            <AssociatedDocuments
                documents={associatedDocs}
                onFileOpen={onFileOpen}
                onDeleteFile={handleDeleteAssociated}
                controlId={procedure.dataroom_control_id}
                onUpdateAttribute={handleUpdateAttribute}
            />

            <RequestModal
                show={showRequestModal}
                onHide={resetModal}
                requestType={requestType}
                setRequestType={setRequestType}
                criteria={criteria}
                setCriteria={setCriteria}
                directRequest={directRequest}
                setDirectRequest={setDirectRequest}
                onSubmit={handleRequestSubmit}
                processingRequests={processingRequests}
                loading={availableDocumentsLoading}
                aiLoading={aiLoading}
                aiError={error}
                createReference={createReference}
                existingDocuments={availableDocuments}    
                linkExistingDocument={handleLinkDocument}
                extractReferences={extractReferences}
                linkMultipleDocuments={linkMultipleDocuments}
                createAndLinkMixedReferences={createAndLinkMixedReferences}
                onFileOpen={onFileOpen}
            />

            {selectedDocument && (
                <UploadSpecificDocumentModal
                    show={showUploadModal}
                    onClose={() => {
                        setShowUploadModal(false);
                        setSelectedDocument(null);
                    }}
                    documentInfo={selectedDocument}
                    onUpload={handleUploadComplete}
                    token={token}
                    dataroomId={dataroomId}
                    onRefetchAudit={fetchReferences}
                />
            )}
        </div>
    );
}

export default DocumentManager;