import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import FileUploadModal from '../../common/FileUploadModal';
import RenameForm from './RenameForm';
import { v4 as uuidv4 } from 'uuid';

import './AddDocument.css';

function AddDocument({ 
    token, 
    dataroomId, 
    refetchFiles, 
    fileInputRef, 
    currentFolder, 
    permissions, 
    setModalProperties,
    createFolder, 
    setCurrentFolder
}) {
    const [showUploadModal, setShowUploadModal] = useState(false);
    const { t } = useTranslation();

    const submitNewFolder = useCallback(async (folder_id, updatedFolderData) => {
        try {
            const { localName, isCategory } = updatedFolderData;
            const uuid = uuidv4();
            const newFolderData = {
                uuid: uuid,
                name: localName,
                dataroom_id: dataroomId,
                parent_id: currentFolder.id === 0 ? null : currentFolder.id,
                is_category: isCategory,
                subcategories: []
            };
            const newFolder = await createFolder(newFolderData);
            setModalProperties(prev => ({ ...prev, isOpen: false }));
            setCurrentFolder(prevFolder => ({
                ...prevFolder,
                subcategories: [...prevFolder.subcategories, newFolder]
            }));
        } catch (error) {
            console.error("Error creating folder:", error);
        }
    }, [createFolder, currentFolder.id, dataroomId, setModalProperties, setCurrentFolder]);

    const handleAddFolder = () => {
        const handleClose = () => setModalProperties(prev => ({ ...prev, isOpen: false }));
        
        setModalProperties({
            title: t('pages.documents.createNewFolder'),
            content: <RenameForm 
                item={{ name: "" }} 
                onHide={handleClose}
                submitAction={submitNewFolder} 
            />,
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false,
            isCentered: true,
            onHide: handleClose
        });
    };

    const handleAddFileClick = () => setShowUploadModal(true);
    
    return (
        <div className="action-buttons">
            <button className="btn-action btn-add-folder" onClick={handleAddFolder}>
                <i className="bi bi-folder-plus"></i>
                <span>{t('pages.documents.createNewFolder')}</span>
            </button>
            <input type="file" style={{ display: 'none' }} ref={fileInputRef} />
            <button className="btn-action btn-add-file" onClick={handleAddFileClick}>
                <i className="bi bi-file-earmark-plus"></i>
                <span>{t('pages.documents.createNewFile')}</span>
            </button>
            <FileUploadModal
                show={showUploadModal}
                onHide={() => setShowUploadModal(false)}
                token={token}
                dataroomId={dataroomId}
                currentFolderId={currentFolder.id}
                refetchFiles={refetchFiles}
            />
        </div>
    );
}

export default AddDocument;