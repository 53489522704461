import React from 'react';
import { Button, Form } from 'react-bootstrap';

const RequestsToolbar = ({
   onNewRequest,
   onSendSelected,
   onDeleteSelected,
   selectedRequests,
   selectedRequestsData,
   onFilterChange
}) => {
   // Vérifie si des requêtes sélectionnées peuvent être envoyées
   const sendableRequestsCount = selectedRequestsData.filter(
       request => request.status === 'created'
   ).length;

   return (
       <div className="d-flex justify-content-between align-items-center mb-3">
           <div>
               <Button 
                   variant="primary" 
                   onClick={onNewRequest}
                   className="me-2"
               >
                   <i className="bi bi-plus-lg me-1"></i>
                   New Request
               </Button>
               {selectedRequests.size > 0 && (
                   <>
                       {sendableRequestsCount > 0 && (
                           <Button 
                               variant="success" 
                               onClick={onSendSelected}
                               className="me-2"
                           >
                               <i className="bi bi-send me-1"></i>
                               Send ({sendableRequestsCount})
                           </Button>
                       )}
                       <Button 
                           variant="danger" 
                           onClick={onDeleteSelected}
                       >
                           <i className="bi bi-trash me-1"></i>
                           Delete ({selectedRequests.size})
                       </Button>
                   </>
               )}
           </div>
           <Form.Control
               type="text"
               placeholder="Filter requests..."
               onChange={(e) => onFilterChange(e.target.value)}
               style={{ maxWidth: '300px' }}
           />
       </div>
   );
};

export default RequestsToolbar;