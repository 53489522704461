import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DocumentItem from './DocumentItem';
import DocumentSection from './DocumentSection';
import AttributesModal from './AttributesModal';
import SummaryModal from './SummaryModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import BankStatementViewer from '../../../common/fileViewers/BankStatementViewer';

const AssociatedDocuments = ({
    documents,
    onFileOpen,
    onDeleteFile,
    controlId,
    onUpdateAttribute
}) => {
    const { t } = useTranslation();
    const [showAttributes, setShowAttributes] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showBankStatement, setShowBankStatement] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileToDelete, setFileToDelete] = useState(null);

    const handleShowAttributes = (file) => {
        setSelectedFile(file);
        setShowAttributes(true);
    };

    const handleShowSummary = (file) => {
        setSelectedFile(file);
        setShowSummary(true);
    };

    const handleDeleteClick = (e, reference) => {
        e.stopPropagation();
        setFileToDelete(reference);
        setShowDeleteModal(true);
    };

    const handleDeleteFromControl = () => {
        onDeleteFile(controlId, fileToDelete.id, false);
        setShowDeleteModal(false);
        setFileToDelete(null);
    };
    
    const handleDeleteCompletely = () => {
        onDeleteFile(controlId, fileToDelete.id, true);
        setShowDeleteModal(false);
        setFileToDelete(null);
    };

    const handleShowBankStatement = (e, reference) => {
        e.stopPropagation();
        setSelectedFile(reference);
        setShowBankStatement(true);
    };

    useEffect(() => {
        if (selectedFile) {
            const updatedFile = documents.find(doc => doc.file?.id === selectedFile.id)?.file;
            if (updatedFile) {
                setSelectedFile(updatedFile);
            }
        }
    }, [documents, selectedFile]);

    return (
        <DocumentSection title={t("pages.workflow.associatedDocuments")}>
            {documents.length > 0 ? (
                <>
                    <div className="dm-list">
                        {documents.map((reference) => (
                            <DocumentItem
                                key={reference.id}
                                name={reference.file?.name}
                                document_type_name={reference.file?.document_type_name}
                                icon="bi-file-earmark-check"
                                onClick={() => onFileOpen(reference.file.presigned_url)}
                                metadata={
                                    <>
                                        {reference.reference_name && (
                                            <div>{t("pages.workflow.reference")}: {reference.reference_name}</div>
                                        )}
                                        {reference.reference_description && (
                                            <div>{reference.reference_description}</div>
                                        )}
                                        {reference.requested_by_username && (
                                            <div>{t("general.addedBy")}: {reference.requested_by_username}</div>
                                        )}
                                    </>
                                }
                                actions={
                                    <>
                                        {reference.accounting_file?.type === 'BANK_STATEMENT' && (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>{t("pages.workflow.viewBankStatement")}</Tooltip>}
                                            >
                                                <span>
                                                    <i 
                                                        className="bi bi-bank dm-action-btn dm-action-btn--list"
                                                        onClick={(e) => handleShowBankStatement(e, reference)}
                                                    />
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                        {reference.file?.summary && (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>{t("pages.workflow.viewSummary")}</Tooltip>}
                                            >
                                                <span>
                                                    <i 
                                                        className="bi bi-file-text dm-action-btn dm-action-btn--list"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleShowSummary(reference.file);
                                                        }}
                                                    />
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                        {reference.file?.extracted_attributes?.length > 0 && (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>{t("pages.workflow.viewAttributes")}</Tooltip>}
                                            >
                                                <span>
                                                    <i 
                                                        className="bi bi-list-ul dm-action-btn dm-action-btn--list"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleShowAttributes(reference.file);
                                                        }}
                                                    />
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>{t("general.delete")}</Tooltip>}
                                        >
                                            <span>
                                                <i 
                                                    className="bi bi-trash dm-action-btn dm-action-btn--delete"
                                                    onClick={(e) => handleDeleteClick(e, reference)}
                                                />
                                            </span>
                                        </OverlayTrigger>
                                    </>
                                }
                            />
                        ))}
                    </div>
                    <AttributesModal 
                        show={showAttributes}
                        onHide={() => setShowAttributes(false)}
                        attributes={selectedFile?.extracted_attributes}
                        onUpdateAttribute={onUpdateAttribute}
                        file={selectedFile}
                    />
                    <SummaryModal
                        show={showSummary}
                        onHide={() => setShowSummary(false)}
                        summary={selectedFile?.summary}
                    />
                    <DeleteConfirmationModal
                        show={showDeleteModal}
                        onHide={() => {
                            setShowDeleteModal(false);
                            setFileToDelete(null);
                        }}
                        fileName={fileToDelete?.file?.name}
                        onDeleteFromControl={handleDeleteFromControl}
                        onDeleteCompletely={handleDeleteCompletely}
                    />
                    <BankStatementViewer
                        show={showBankStatement}
                        onHide={() => setShowBankStatement(false)}
                        bankStatementData={selectedFile?.accounting_file}
                    />
                </>
            ) : (
                <p className="dm-empty">{t("pages.workflow.noAssociatedDocuments")}</p>
            )}
        </DocumentSection>
    );
};

export default AssociatedDocuments;