import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './DeleteConfirmationModal.css';
import { useTranslation } from 'react-i18next';
const DeleteConfirmationModal = ({ show, onHide, onDeleteFromControl, onDeleteCompletely, fileName }) => {
    const { t } = useTranslation();
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            className="dm-modal"
            dialogClassName="dm-modal-dialog"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("pages.documents.deleteDocument")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-4">
                    {t("pages.workflow.deleteConfirmation")} <strong>{fileName}</strong>:
                </p>
                <div className="delete-options">
                    <div 
                        className="delete-option"
                        onClick={onDeleteFromControl}
                    >
                        <div className="delete-option-icon">
                            <i className="bi bi-heartbreak"></i>
                        </div>
                        <div className="delete-option-content">
                            <h6>{t("pages.workflow.removeFromControlOnly")}</h6>
                            <p>{t("pages.workflow.documentWillRemainInTheDataroomButWillBeUnlinkedFromThisControl")}</p>
                        </div>
                    </div>
                    <div 
                        className="delete-option delete-option--danger"
                        onClick={onDeleteCompletely}
                    >
                        <div className="delete-option-icon">
                            <i className="bi bi-trash"></i>
                        </div>
                        <div className="delete-option-content">
                            <h6>{t("pages.workflow.deleteCompletely")}</h6>
                            <p>{t("pages.workflow.documentWillBePermanentlyDeletedFromTheDataroom")}</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onHide}>{t("general.cancel")}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmationModal;