import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form, Button } from 'react-bootstrap';

const NewRequestForm = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    title: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const isFormValid = formData.title.trim();

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="mb-4">
        <Card.Body>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="title">
              {t('pages.workflow.documentSection.requests.newTitle')}
            </Form.Label>
            <Form.Control
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder={t('pages.workflow.documentSection.requests.newTitlePlaceholder')}
            />
          </Form.Group>
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-end">
        <Button 
          type="submit" 
          disabled={!isFormValid || isSubmitting}
        >
          {isSubmitting 
            ? t('general.processing')
            : t('pages.workflow.documentSection.requests.createRequest')
          }
        </Button>
      </div>
    </Form>
  );
};

export default NewRequestForm;