import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from "react-bootstrap";
import { createPortal } from 'react-dom';
import DeleteRequest from '../DeleteRequest';
import ActivitiesList from '../../general/ActivitiesList';

function RowActions({ item, setModalState, closeModal, submitDelete }) {
    const { t } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const [alignLeft, setAlignLeft] = useState(false);
    const toggleRef = useRef(null);
    const menuRef = useRef(null);

    const canDelete = item.permissions && item.permissions.Delete;

    const handleActivities = (item) => {
        setModalState(prevState => ({
            ...prevState,
            title: t('pages.activities.title'),
            content: <ActivitiesList object_type="IRL" object_id={item.irl_id} />,
            isOpen: true,
            fullscreen: false,
            centered: true
        }));
    };

    const handleDelete = (item) => {
        setModalState(prevState => ({
            ...prevState,
            title: t('pages.qa.deleteRequest'),
            content: <DeleteRequest item={item} closeModal={closeModal} submitDelete={submitDelete} />,
            isOpen: true,
            fullscreen: false,
            centered: true
        }));
    };

    useEffect(() => {
        if (toggleRef.current && dropdownOpen) {
            const rect = toggleRef.current.getBoundingClientRect();
            const spaceRight = window.innerWidth - rect.right;
            const spaceLeft = rect.left;
            const dropdownWidth = 200; 

            setAlignLeft(spaceRight < dropdownWidth && spaceLeft > spaceRight);

            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: alignLeft ? rect.left + window.scrollX - dropdownWidth + rect.width : rect.left + window.scrollX
            });
        }
    }, [dropdownOpen, alignLeft]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target) &&
                toggleRef.current && !toggleRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const renderDropdownMenu = () => {
        return createPortal(
            <div 
                ref={menuRef}
                className="action-dropdown-menu"
                style={{
                    position: 'fixed',
                    top: `${dropdownPosition.top}px`,
                    left: `${dropdownPosition.left}px`,
                    display: dropdownOpen ? 'block' : 'none'
                }}
            >
                <Dropdown.Item onClick={() => handleActivities(item)}>
                    <i className="bi bi-clock-history icon-spacing"></i> {t('pages.activities.title')}
                </Dropdown.Item>
                {canDelete && (
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => handleDelete(item)}>
                            <i className="bi bi-trash icon-spacing"></i> {t('general.delete')}
                        </Dropdown.Item>
                    </>
                )}
            </div>,
            document.body
        );
    };

    return (
        <div className="action-dropdown-wrapper">
            <div 
                ref={toggleRef}
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="action-icon cursorHover no-arrow"
            >
                <i className="bi bi-three-dots"></i>
            </div>
            {renderDropdownMenu()}
        </div>
    );
}

export default RowActions;