import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Card } from 'react-bootstrap';
import './ProcedureCard.css';

function ProcedureCard({ procedure, onClick, priorityNameToKey, statusNameToKey }) {
    const { t } = useTranslation();
    const getStatusInfo = (status) => {
        switch (status.toLowerCase()) {
            case 'completed':
                return { color: '#28a745', text: t(statusNameToKey['Completed']) };
            case 'in progress':
                return { color: '#007bff', text: t(statusNameToKey['In Progress']) };
            case 'review':
                return { color: '#ffc107', text: t(statusNameToKey['Review']) };
            case 'ready':
                return { color: '#17a2b8', text: t(statusNameToKey['Ready']) };
            case 'missing documents':
                return { color: '#dc3545', text: t(statusNameToKey['Missing Documents']) };
            default:
                return { color: '#6c757d', text: status };
        }
    };

    const getPriorityLabel = (priority) => {
        return priority ? t(priorityNameToKey[priority] || priority) : t("general.unknown");
    };

    const statusInfo = getStatusInfo(procedure.status);

    /* TODO: Add assertions 
    <Card.Subtitle className="mb-2 text-muted">
        <i className="bi bi-tags me-2"></i>
        {procedure.assertions && procedure.assertions.length > 0 
            ? procedure.assertions.map(assertion => assertion.title).join(', ')
            : t("pages.workflow.noAssertions")}
    </Card.Subtitle>
    */
    return (
        <Card className="procedure-card" onClick={onClick} style={{ borderTopColor: statusInfo.color }}>
            <Card.Body>
                <Card.Title>
                    <i className="bi bi-clipboard-data me-2"></i>
                    {procedure.name}
                </Card.Title>
                <div className="status-container procedure-card-status">
                    <span 
                        className="status-badge"
                        style={{ backgroundColor: statusInfo.color }}
                    >
                        {statusInfo.text}
                    </span>
                </div>
                <div className="procedure-footer">
                    <Badge bg="secondary" className="priority-badge">
                        <i className="bi bi-flag-fill me-1"></i>
                        {t("pages.workflow.priorityDoubleDots")} {getPriorityLabel(procedure.priority)}
                    </Badge>
                </div>
            </Card.Body>
        </Card>
    );
}

export default ProcedureCard;