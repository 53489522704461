import React, { useState } from 'react';
import RequestsToolbar from '../components/RequestsToolbar';
import DocumentRequestTable from './DocumentRequestTable';
import DocumentRequestDetails from './DocumentRequestDetails';
import GenericModal from '../../../general/GenericModal';

const MOCK_DOCUMENTS = [
    {
        id: 1,
        title: 'December 2023 Sales Invoices',
        control: 'Revenue Testing - Existence and Accuracy',
        document_type: 'Invoice',
        creation_date: '2024-01-05',
        created_by: { id: 1, name: 'John Smith' },
        assigned_to: { id: 3, name: 'Client Manager' },
        status: 'sent',
        description: 'Sample invoices for December 2023',
        uploaded_at: null,
        uploaded_by: null,
        uploaded_file: null
    },
    {
        id: 2,
        title: 'Purchase Orders > 10k€',
        control: 'Testing of Purchase Authorization',
        document_type: 'Purchase Order',
        creation_date: '2024-01-03',
        created_by: { id: 2, name: 'Emma Davis' },
        assigned_to: { id: 3, name: 'Client Manager' },
        status: 'completed',
        description: 'POs above 10k€',
        uploaded_at: '2024-01-06',
        uploaded_by: { id: 3, name: 'Client Manager' },
        uploaded_file: {
            id: 'doc_123',
            name: 'PO_above_10k_2023.pdf',
            url: '/documents/doc_123'
        }
    },
    {
        id: 3,
        title: 'Bank Statements Q4 2023',
        control: 'Cash and Bank Reconciliation',
        document_type: 'Bank Statement',
        creation_date: '2024-01-08',
        created_by: { id: 1, name: 'John Smith' },
        assigned_to: { id: 3, name: 'Client Manager' },
        status: 'created',
        description: 'Bank statements for all accounts - Q4 2023',
        uploaded_at: null,
        uploaded_by: null,
        uploaded_file: null
    }
];

const DocumentRequestList = ({ token, dataroomId }) => {
    const [selectedRequests, setSelectedRequests] = useState(new Set());
    const [filter, setFilter] = useState('');
    const [requests, setRequests] = useState(MOCK_DOCUMENTS);
    const [selectedRequest, setSelectedRequest] = useState(null);

    // Obtenir les données complètes des requêtes sélectionnées
    const selectedRequestsData = requests.filter(
        request => selectedRequests.has(request.id)
    );

    const handleNewRequest = () => {
        // TODO: Ouvrir modal pour nouvelle requête
        console.log('New request');
    };

    const handleSendSelected = () => {
        // Filtrer pour n'envoyer que les requêtes avec le statut 'created'
        const sendableRequests = selectedRequestsData.filter(
            request => request.status === 'created'
        );
        
        console.log('Sending requests:', sendableRequests);
        
        // Mettre à jour le statut des requêtes envoyées
        setRequests(requests.map(request => {
            if (selectedRequests.has(request.id) && request.status === 'created') {
                return { ...request, status: 'sent' };
            }
            return request;
        }));
        
        // Vider la sélection
        setSelectedRequests(new Set());
    };

    const handleDeleteSelected = () => {
        setRequests(requests.filter(req => !selectedRequests.has(req.id)));
        setSelectedRequests(new Set());
    };

    const handleRowClick = (request) => {
        setSelectedRequest(request);
    };

    const handleRefetchData = () => {
        // TODO: Implement data refetch
        console.log('Refetching data...');
    };

    return (
        <div>
            <RequestsToolbar
                onNewRequest={handleNewRequest}
                onSendSelected={handleSendSelected}
                onDeleteSelected={handleDeleteSelected}
                selectedRequests={selectedRequests}
                selectedRequestsData={selectedRequestsData}
                onFilterChange={setFilter}
            />
            <DocumentRequestTable
                requests={requests}
                selectedRequests={selectedRequests}
                onSelectionChange={setSelectedRequests}
                filter={filter}
                onRowClick={handleRowClick}
            />

            <GenericModal
                show={selectedRequest !== null}
                onHide={() => setSelectedRequest(null)}
                title={selectedRequest?.title || 'Document Request Details'}
                centered
                fullscreen={true}
            >
                {selectedRequest && (
                    <DocumentRequestDetails 
                        request={selectedRequest}
                        token={token}
                        dataroomId={dataroomId}
                        onRefetchData={handleRefetchData}
                    />
                )}
            </GenericModal>
        </div>
    );
};

export default DocumentRequestList;