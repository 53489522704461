import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';

function DeleteNote({ note_id, closeModal, deleteNote }) {
    const { t } = useTranslation();

    const submitDelete = () => {
        deleteNote(note_id);
        closeModal();
    }

    return (
        <Button variant="danger" className="my-3" onClick={submitDelete}>
            {t("general.delete")}
        </Button>
    );
}
export default DeleteNote;
