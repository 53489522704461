import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { DataRoomContext } from '../DisplayDataRoom';
import { formatDate } from '../../utils/Date';
import { TEAM_URL } from '../../utils/urls';
import { useFetchActivitiesList } from '../../hooks/activities/useFetchActivitiesList'; 

import './ActivitiesList.css'; 

function ActivitiesList({ object_type, object_id }) {
    const { t } = useTranslation();
    const dataroomId = useContext(DataRoomContext);
    const { activities, isLoading, error } = useFetchActivitiesList(dataroomId, object_type, object_id);

    if (isLoading) {
        return <div>{t("loading")}</div>;
    }

    if (error) {
        return <div>{t("general.error")} {error}</div>;
    }

    return (
        <div className="activity-list">
            {activities.length > 0 ? activities.map(activity => {
                const descriptionWithoutBrackets = activity.description.replace(/\[|\]/g, '');

                return (
                    <div key={activity.event_id} className="activity-item mb-2">
                        <Link to={TEAM_URL.replace(':dataroomId', dataroomId).replace(':teamId', activity.triggered_by)}>
                            {activity.triggered_by_username}
                        </Link>{" "}
                        {descriptionWithoutBrackets}
                        <div className="activity-date text-muted">
                            {formatDate(activity.created_at, t)} 
                        </div>
                    </div>
                );
            }) : <div className="no-activities-message">No activities found.</div>}
        </div>
    );
}

export default ActivitiesList;
