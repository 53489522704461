import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Modal } from 'react-bootstrap';
import { useControlUpdates } from '../../hooks/workflow/useControls';
import { useUser } from '../../general/UserProvider';
import ProcedureDetailsLeft from './ProcedureDetailsLeft';
import ProcedureDetailsRight from './ProcedureDetailsRight';
import ControlCopilot from './copilot/ControlCopilot';
import './ProcedureDetail.css';

function ProcedureDetail({ 
    procedure, 
    dataroomId,
    onStartProcedure,  
    onFileOpen,
    refetch,
    priorityNameToKey,
    statusNameToKey
}) {
    const { token } = useUser();
    const { t } = useTranslation();
    const [showCopilot, setShowCopilot] = useState(false);

    const [findings, setFindings] = useState(procedure.results || '');
    const [conclusion, setConclusion] = useState(procedure.conclusion || '');
    const [assessment, setAssessment] = useState(procedure.assessment || '');

    const { 
        updatePriorityDescription, 
        updatePriority, 
        updateStatus,
        updateName,
        updateDescription,
        updateSteps,
        updateResults,
        updateConclusion,
        updateAssessment,
        updating,
        updateError
    } = useControlUpdates(token, dataroomId);

    const handlePriorityDescriptionUpdate = async (description) => {
        const success = await updatePriorityDescription(procedure.dataroom_control_id, description);
        if (success) {
            procedure.priority_description = description;
        }
        return success;
    };

    const handlePriorityUpdate = async (priority) => {
        const success = await updatePriority(procedure.dataroom_control_id, priority);
        if (success) {
            procedure.priority = priority;
        }
        return success;
    };

    const handleStatusUpdate = async (newStatus) => {
        const success = await updateStatus(procedure.dataroom_control_id, newStatus);
        if (success) {
            procedure.status = newStatus;
        }
        return success;
    };

    const handleNameUpdate = async (newName) => {
        const success = await updateName(procedure.dataroom_control_id, newName);
        if (success) {
            procedure.name = newName;
        }
        return success;
    };

    const handleDescriptionUpdate = async (newDescription) => {
        const success = await updateDescription(procedure.dataroom_control_id, newDescription);
        if (success) {
            procedure.description = newDescription;
        }
        return success;
    };

    const handleStepsUpdate = async (newSteps) => {
        const success = await updateSteps(procedure.dataroom_control_id, newSteps);
        if (success) {
            procedure.steps = newSteps;
        }
        return success;
    };

    const handleResultsUpdate = async (newResults) => {
        const success = await updateResults(procedure.dataroom_control_id, newResults);
        if (success) {
            setFindings(newResults);
            procedure.results = newResults;
        }
        return success;
    };

    const handleConclusionUpdate = async (newConclusion) => {
        const success = await updateConclusion(procedure.dataroom_control_id, newConclusion);
        if (success) {
            setConclusion(newConclusion);
            procedure.conclusion = newConclusion;
        }
        return success;
    };

    const handleAssessmentUpdate = async (newAssessment) => {
        const success = await updateAssessment(procedure.dataroom_control_id, newAssessment);
        if (success) {
            setAssessment(newAssessment);
            procedure.assessment = newAssessment;
        }
        return success;
    };

    return (
        <div className="procedure-detail h-100 d-flex flex-column">
            <Row className="g-0 flex-grow-1">
                <Col md={8} className="procedure-detail-left">
                    <ProcedureDetailsLeft 
                        procedure={procedure}
                        findings={findings}
                        conclusion={conclusion}
                        assessment={assessment}
                        onUpdateName={handleNameUpdate}
                        onUpdateDescription={handleDescriptionUpdate}
                        onUpdateSteps={handleStepsUpdate}
                        onUpdateResults={handleResultsUpdate}
                        onUpdateConclusion={handleConclusionUpdate}
                        onUpdateAssessment={handleAssessmentUpdate}
                        onCopilotClick={() => setShowCopilot(true)}
                        updating={updating}
                        updateError={updateError}
                    />
                </Col>
                <Col md={4} className="procedure-detail-right">
                    <ProcedureDetailsRight 
                        procedure={procedure}
                        setFindings={setFindings}
                        setConclusion={setConclusion}
                        setAssessment={setAssessment}
                        refetch={refetch}
                        onStartProcedure={onStartProcedure}
                        onFileOpen={onFileOpen}
                        supervisor={procedure.supervisor_username}
                        auditor={procedure.auditor_username}
                        onUpdatePriorityDescription={handlePriorityDescriptionUpdate}
                        onUpdatePriority={handlePriorityUpdate}
                        onUpdateStatus={handleStatusUpdate}
                        updating={updating}
                        updateError={updateError}
                        token={token}
                        dataroomId={dataroomId}
                        priorityNameToKey={priorityNameToKey}
                        statusNameToKey={statusNameToKey}
                    />
                </Col>
            </Row>

            <Modal 
                show={showCopilot} 
                onHide={() => setShowCopilot(false)}
                fullscreen={true}
                className="copilot-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="d-flex align-items-center gap-2">
                            <i className="bi bi-robot fs-4"></i>
                            <span>{t("pages.workflow.copilot")} - {procedure.name}</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ControlCopilot control_id={procedure.dataroom_control_id} />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ProcedureDetail;