import React from 'react';
import { useTranslation } from 'react-i18next';

import CommentSection from '../../general/CommentSection';
import FileActions from './FileActions';
import { formatDate } from '../../../utils/Date';

function FileRow({ item, setModalProperties, handleFileClick, updateFile, refetchFiles, currentFolder, deleteFile }) {
    const { t } = useTranslation();
    const canComment = item?.permissions?.Comment;

    const handleComments = () => {
        setModalProperties(prevProps => ({
            ...prevProps,
            title: t('comment.title'),
            content: <CommentSection 
                objectId={item.id} 
                objectType="File" 
                onCommentChange={() => {}}
                canComment={canComment}
            />,
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false
        }));
    };

    const getFileIcon = (filename) => {
        const extension = filename.split('.').pop();
        switch (extension) {
            case 'pdf':
                return <i className="bi bi-filetype-pdf icon-spacing"></i>;
            case 'txt':
                return <i className="bi bi-filetype-txt icon-spacing"></i>;
            case 'doc':
                return <i className="bi bi-filetype-doc icon-spacing"></i>;
            case 'docx':
                return <i className="bi bi-filetype-docx icon-spacing"></i>;
            case 'ppt':
                return <i className="bi bi-filetype-ppt icon-spacing"></i>;
            case 'pptx':
                return <i className="bi bi-filetype-pptx icon-spacing"></i>;
            default:
                return <i className="bi bi-file-text icon-spacing"></i>;
        }
    };

    return (
        <tr className="tableRow">
            <td onClick={() => handleFileClick(item)} className='cursorHover'>
                {getFileIcon(item.name)}
                {item.name}
                {item.document_type_name && (
                    <span style={{ fontStyle: 'italic', color: '#888', marginLeft: '8px' }}>
                        {item.document_type_name}
                    </span>
                )}
            </td>
            <td>{formatDate(item.created_at, t)}</td>
            <td>
                <FileActions
                    item={item}
                    handleComments={handleComments}
                    setModalProperties={setModalProperties}
                    updateFile={updateFile}
                    refetchFiles={refetchFiles}
                    currentFolder={currentFolder}
                    deleteFile={deleteFile}
                    extractedAttributes={item.extracted_attributes}
                />
            </td>
        </tr>
    );
};

export default FileRow;