import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Container, Alert } from 'react-bootstrap';
import { useFetchCycles } from '../../../hooks/admin/cyclesHooks';
import { useFetchAssertions } from '../../../hooks/admin/assertionsHooks';
import { useFetchDocumentTypes } from '../../../hooks/admin/docTypesHooks';
import { 
 useAddRequiredDocument,
 useDeleteRequiredDocument, 
 useFetchAllRequiredDocuments,
 useControlDocumentLink 
} from '../../../hooks/admin/requiredDocumentsHooks';
import ControlSteps from './ControlSteps';
import ControlAssertions from './ControlAssertions';
import ControlRequiredDocuments from './ControlRequiredDocuments';

const ControlEditModal = ({ 
 show, 
 handleClose, 
 control, 
 onSave, 
 isProcessing, 
 error,
 token,
 currentAssertions,
 onAssertionsChange
}) => {
 const [formData, setFormData] = useState({
   name: '',
   description: '',
   order: 0,
   steps: [],
   cycle_id: '',
   required_documents: []
 });

 const [selectedDocuments, setSelectedDocuments] = useState([]);
 const [allRequiredDocuments, setAllRequiredDocuments] = useState([]);

 const { cycles, loading: cyclesLoading } = useFetchCycles(token);
 const { assertions, loading: assertionsLoading } = useFetchAssertions(token);
 const { documentTypes, loading: documentTypesLoading } = useFetchDocumentTypes(token);
 const { addRequiredDocument, adding: addingDoc } = useAddRequiredDocument(token);
 const { deleteRequiredDocument, deleting: deletingDoc } = useDeleteRequiredDocument(token);
 const { requiredDocuments: allRequiredDocsData, loading: requiredDocsLoading } = useFetchAllRequiredDocuments(token);
 const { 
  addControlDocument, 
  deleteControlDocument,
  linking,
  unlinking,
  linkError 
} = useControlDocumentLink(token);

 useEffect(() => {
   if (control) {
     setFormData({
       name: control.name || '',
       description: control.description || '',
       order: control.order || 0,
       steps: control.steps || [],
       cycle_id: control.cycle?.id || '',
       required_documents: control.required_documents || []
     });
     setSelectedDocuments(control.required_documents?.map(doc => doc.id.toString()) || []);
   } else {
     setFormData({
       name: '',
       description: '',
       order: 0,
       steps: [],
       cycle_id: '',
       required_documents: []
     });
     setSelectedDocuments([]);
   }
 }, [control]);
 
 useEffect(() => {
   setAllRequiredDocuments(allRequiredDocsData || []);
 }, [allRequiredDocsData]);

 const handleChange = (e) => {
   const { name, value } = e.target;
   setFormData(prevData => ({
     ...prevData,
     [name]: name === 'order' ? parseInt(value, 10) : value
   }));
 };

 const handleStepChange = (index, value) => {
   const updatedSteps = [...formData.steps];
   updatedSteps[index] = value;
   setFormData(prevData => ({
     ...prevData,
     steps: updatedSteps
   }));
 };

 const addStep = () => {
   setFormData(prevData => ({
     ...prevData,
     steps: [...prevData.steps, '']
   }));
 };

 const removeStep = (index) => {
   const updatedSteps = formData.steps.filter((_, i) => i !== index);
   setFormData(prevData => ({
     ...prevData,
     steps: updatedSteps
   }));
 };

 const handleSubmit = (e) => {
   e.preventDefault(); 
   if (!isProcessing) { 
     const completeFormData = {
       ...formData,
       assertion_ids: currentAssertions,
       required_documents: allRequiredDocuments.filter(doc => 
         selectedDocuments.includes(doc.id.toString())
       )
     };
     onSave(completeFormData, true);
   }
 };

 const toggleAssertion = async (assertionId, event) => {
   event.preventDefault();
   event.stopPropagation();

   const newAssertions = currentAssertions.includes(assertionId.toString())
     ? currentAssertions.filter(id => id !== assertionId.toString())
     : [...currentAssertions, assertionId.toString()];
   
   onAssertionsChange(newAssertions);
   
   const updatedData = {
     ...formData,
     assertion_ids: newAssertions
   };
   onSave(updatedData, false);
 };

 const handleToggleDocument = async (docId) => {
    try {
      if (selectedDocuments.includes(docId.toString())) {
        // Remove link
        const success = await deleteControlDocument(control.id, docId);
        if (success) {
          setSelectedDocuments(prev => prev.filter(id => id !== docId.toString()));
        }
      } else {
        // Add link
        const result = await addControlDocument(control.id, { document_requirement_id: docId });
        if (result) {
          setSelectedDocuments(prev => [...prev, docId.toString()]);
        }
      }
    } catch (error) {
      console.error('Error toggling document:', error);
    }
  };

  const handleAddDocument = async (newDoc) => {
    try {
      // First create the document
      const createdDoc = await addRequiredDocument({
        document_type_id: newDoc.document_type_id,
        fiscal_year: newDoc.fiscal_year,
        description: newDoc.description,
        is_template: true
      });
  
      if (createdDoc && createdDoc.id) {
        // Then create the link
        const result = await addControlDocument(control.id, {
          document_requirement_id: createdDoc.id
        });
        
        if (result) {
          setAllRequiredDocuments(prev => [...prev, createdDoc]);
          setSelectedDocuments(prev => [...prev, createdDoc.id.toString()]);
        }
      }
    } catch (error) {
      console.error('Error adding document:', error);
    }
  };

  const handleDeleteDocument = async (docId) => {
    try {
      // First remove the link
      const unlinkSuccess = await deleteControlDocument(control.id, docId);
      
      if (unlinkSuccess) {
        // Then delete the document if it's not used elsewhere
        const deleteSuccess = await deleteRequiredDocument(docId);
        if (deleteSuccess) {
          setAllRequiredDocuments(prev => prev.filter(doc => doc.id !== docId));
          setSelectedDocuments(prev => prev.filter(id => id !== docId.toString()));
        }
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

 const isFormValid = () => {
   return formData.name.trim() !== '' && 
          formData.description.trim() !== '' &&
          !isNaN(formData.order) &&
          formData.steps.length > 0 &&
          formData.steps.every(step => step.trim() !== '') &&
          formData.cycle_id !== '';
 };

 return (
   <Modal show={show} onHide={handleClose} className="edit-modal modal-fullscreen" centered>
     <Modal.Header>
       <Container fluid>
         <Row className="align-items-center">
           <Col xs="auto">
             <Button variant="link" onClick={handleClose} className="p-0 text-dark">
               <i className="bi bi-x-lg"></i>
             </Button>
           </Col>
           <Col>
             <Modal.Title>{control ? 'Edit Control' : 'Create Control'}</Modal.Title>
           </Col>
           <Col xs="auto">
             <Button 
               variant="primary" 
               onClick={handleSubmit} 
               disabled={isProcessing || !isFormValid()}
             >
               {isProcessing ? 'Processing...' : (control ? 'Save Changes' : 'Create Control')}
             </Button>
           </Col>
         </Row>
       </Container>
     </Modal.Header>
     <Modal.Body>
       <Container fluid>
         {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
         <Form noValidate onSubmit={handleSubmit}>
           <h2 className="title-h2 font-title mx-3">Control Details</h2>
           <div className="px-4">
             <Form.Group className="mb-3">
               <Form.Label>Name</Form.Label>
               <Form.Control
                 name="name"
                 value={formData.name}
                 onChange={handleChange}
                 placeholder="e.g., Bank Reconciliation"
                 required
               />
             </Form.Group>

             <Form.Group className="mb-3">
               <Form.Label>Description</Form.Label>
               <Form.Control
                 name="description"
                 as="textarea"
                 rows={3}
                 value={formData.description}
                 onChange={handleChange}
                 placeholder="Describe the purpose and scope of this control"
                 required
               />
             </Form.Group>

             <Form.Group className="mb-3">
               <Form.Label>Order</Form.Label>
               <Form.Control
                 name="order"
                 type="number"
                 value={formData.order}
                 onChange={handleChange}
                 placeholder="Enter the order of this control"
                 required
               />
             </Form.Group>

             <Form.Group className="mb-3">
               <Form.Label>Cycle</Form.Label>
               <Form.Select
                 name="cycle_id"
                 value={formData.cycle_id}
                 onChange={handleChange}
                 required
                 disabled={cyclesLoading}
               >
                 <option value="">Select a cycle</option>
                 {cycles.map((cycle) => (
                   <option key={cycle.id} value={cycle.id}>
                     {cycle.name}
                   </option>
                 ))}
               </Form.Select>
             </Form.Group>

              <ControlSteps 
                steps={formData.steps}
                onStepChange={handleStepChange}
                onAddStep={addStep}
                onRemoveStep={removeStep}
              />

              <ControlAssertions
                assertions={assertions}
                currentAssertions={currentAssertions}
                loading={assertionsLoading}
                onToggle={toggleAssertion}
              />

              <ControlRequiredDocuments
                allRequiredDocuments={allRequiredDocuments}
                selectedDocuments={selectedDocuments}
                documentTypes={documentTypes}
                loading={requiredDocsLoading || documentTypesLoading}
                onToggleDocument={handleToggleDocument}
                onAddDocument={handleAddDocument}
                onDeleteDocument={handleDeleteDocument}
                isAdding={addingDoc || linking}
                isDeleting={deletingDoc || unlinking}
                error={linkError}
              />
           </div>
         </Form>
       </Container>
     </Modal.Body>
   </Modal>
 );
};

export default ControlEditModal;