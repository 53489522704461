import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils/Date';


function ProcedureInformation({ 
    procedure,
    supervisor,
    auditor
}) {
    const { t } = useTranslation();

    return (
        <div className="info-section">
            <h3 className="section-title-right">{t("general.information")}</h3>
            <div className="info-grid">
                <div className="info-item">
                    <span className="info-label">
                        <i className="bi bi-person-badge me-2"></i>
                        {t("pages.workflow.auditor")}
                    </span>
                    <span className="info-value">{auditor}</span>
                </div>
                <div className="info-item">
                    <span className="info-label">
                        <i className="bi bi-person-check me-2"></i>
                        {t("pages.workflow.supervisor")}
                    </span>
                    <span className="info-value">{supervisor}</span>
                </div>
                <div className="info-item">
                    <span className="info-label">
                        <i className="bi bi-calendar-plus me-2"></i>
                        {t("general.createdOn")}
                    </span>
                    <span className="info-value">{formatDate(procedure.created_at, t)}</span>
                </div>
                <div className="info-item">
                    <span className="info-label">
                        <i className="bi bi-calendar-check me-2"></i>
                        {t("general.lastUpdated")}
                    </span>
                    <span className="info-value">{formatDate(procedure.updated_at, t)}</span>
                </div>
            </div>
        </div>
    );
}

export default ProcedureInformation;