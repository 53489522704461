import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ProgressBar, Button } from 'react-bootstrap';

const steps = [
  {
    id: 'starting',
    label: 'Initialisation of analysis',
    icon: 'rocket-takeoff'
  },
  {
    id: 'findings',
    label: 'Searching for findings',
    icon: 'search'
  },
  {
    id: 'conclusion',
    label: 'Writing conclusions',
    icon: 'pencil-square'
  },
  {
    id: 'completed',
    label: 'Analysis completed',
    icon: 'check-circle'
  }
];

const getProgressFromStep = (status) => {
  switch (status) {
    case 'starting':
      return 5;
    case 'findings':
      return 20;
    case 'conclusion':
      return 65;
    case 'completion':
      return 100;
    default:
      return 0;
  }
};

function ModalStartProcedure({ 
    show, 
    onClose, 
    onComplete,
    executing,
    executionStatus,
    error,
    progress 
}) {
    const { t } = useTranslation();
  const getCurrentStepIndex = () => {
    const stepIndex = steps.findIndex(step => step.id === executionStatus);
    return stepIndex === -1 ? 0 : stepIndex;
  };

  const currentProgress = getProgressFromStep(executionStatus);
  const currentStepIndex = getCurrentStepIndex();
  const isCompleted = executionStatus === 'completion';

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
      className="dm-modal"
    >
      <Modal.Header>
        <Modal.Title className="w-100 text-center">
          {t("pages.workflow.auditProcedureInProgress")}
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        {error ? (
          <div className="alert alert-danger text-center">
            {error}
          </div>
        ) : (
          <>
            <div className="mb-4">
              <ProgressBar 
                now={currentProgress} 
                animated={executing}
                variant={isCompleted ? "success" : "primary"}
              />
            </div>

            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {steps.map((step, index) => {
                const isCurrentStep = index === currentStepIndex;
                const isCompleted = index < currentStepIndex || executionStatus === 'completion';
                
                return (
                  <div
                    key={step.id}
                    className={`d-flex align-items-center p-3 rounded mb-2 ${
                      isCurrentStep
                        ? 'bg-primary bg-opacity-10 border border-primary'
                        : isCompleted
                        ? 'bg-success bg-opacity-10 border border-success'
                        : 'bg-light border'
                    }`}
                  >
                    <i
                      className={`bi bi-${step.icon} me-3 ${
                        isCurrentStep
                          ? 'text-primary'
                          : isCompleted
                          ? 'text-success'
                          : 'text-secondary'
                      }`}
                    ></i>
                    <span
                      className={
                        isCurrentStep
                          ? 'text-primary'
                          : isCompleted
                          ? 'text-success'
                          : 'text-secondary'
                      }
                    >
                      {step.label}
                      {isCurrentStep && executing && (
                        <span className="ms-2 spinner-grow spinner-grow-sm" />
                      )}
                    </span>
                    {isCompleted && (
                      <i className="bi bi-check-lg ms-auto text-success"></i>
                    )}
                  </div>
                );
              })}
            </div>

            <p className="text-center text-muted mt-3 mb-0">
              {isCompleted
                ? t("pages.workflow.analysisCompleted")
                : t("pages.workflow.pleaseWaitWhileWeAnalyzeYourData")}
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant={error ? "secondary" : "primary"}
          onClick={() => {
            onClose();
            if (isCompleted) {
              onComplete();
            }
          }}
          disabled={!error && !isCompleted}
        >
          {error ? t("general.close") : isCompleted ? t("general.done") : t("general.processing")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalStartProcedure;