import React from 'react';
import { Form, ListGroup, Alert } from 'react-bootstrap';

const ControlAssertions = ({ assertions, currentAssertions, loading, onToggle }) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label>Assertions</Form.Label>
      {loading ? (
        <Alert variant="info">Loading assertions...</Alert>
      ) : (
        <ListGroup className="mt-2">
          {assertions.map(assertion => (
            <ListGroup.Item 
              key={assertion.id}
              action
              active={currentAssertions.includes(assertion.id.toString())}
              onClick={(e) => onToggle(assertion.id, e)}
              className="d-flex align-items-center"
            >
              <div className="me-2">
                {currentAssertions.includes(assertion.id.toString()) ? (
                  <i className="bi bi-check-circle-fill text-success"></i>
                ) : (
                  <i className="bi bi-circle text-muted"></i>
                )}
              </div>
              <div>
                <div className="fw-medium">{assertion.title}</div>
                {assertion.description && (
                  <small className="text-muted">{assertion.description}</small>
                )}
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Form.Group>
  );
};

export default ControlAssertions;