import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import { Card, Table, Badge, Modal, Button } from 'react-bootstrap';
import LayoutPageHeader from '../../general/LayoutPageHeader';
import CustomFilesViewer from '../../general/CustomFilesViewer';
import GenericModal from '../../general/GenericModal';
import ProcedureList from './ProcedureList';
import ProcedureDetail from './ProcedureDetail';
import AuditOverview from './overview/AuditOverview';
import { DataRoomContext } from '../DisplayDataRoom';
import { useUser } from '../../general/UserProvider';

import { useWorkflow } from '../../hooks/workflow/useControls';
import { useAudit } from '../../hooks/workflow/useAudit';

import './Workflow.css';

function Workflow() {
    const { cycleId, controlId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);    
    const [currentCycle, setCurrentCycle] = useState(null);
    const { workflowData,  loading: loadingControls, error: errorControls, refetch: refetchWorkflow } = useWorkflow(token, dataroom_id);
    const { 
        auditData, 
        loading: loadingAudit, 
        error: errorAudit,
        preparingAudit,
        prepareError,
        prepareAudit,
        preparationStatus,
        refetch: refetchAudit
    } = useAudit(token, dataroom_id);

    const isLoading = loadingControls || loadingAudit;
    const error = errorControls || errorAudit;

    const cycleNameToKey = useMemo(() => ({
        "Treasury": "cycles.treasury",
        "Purchases": "cycles.purchases", 
        "Sales": "cycles.sales",
        "Fixed Assets": "cycles.fixedAssets",
        "Human Resources": "cycles.humanResources",
        "Tax": "cycles.tax",
        "Equity and Provisions": "cycles.equityProvisions",
        "Other accounts": "cycles.otherAccounts"
    }), []);

    const priorityNameToKey = {
        "Very Low": "priorities.veryLow",
        "Low": "priorities.low",
        "Medium": "priorities.medium",
        "High": "priorities.high",
        "Critical": "priorities.critical"
    };

    const statusNameToKey = {
        "Missing Documents": "pages.workflow.missingDocuments",
        "Ready": "pages.workflow.ready",
        "In Progress": "pages.workflow.inProgress",
        "Review": "pages.workflow.forReview",
        "Completed": "pages.workflow.completed"
    };

    const refetchAll = () => {
        refetchWorkflow();
        refetchAudit();
    };

    const [fileModalProperties, setFileModalProperties] = useState({
        isOpen: false,
        title: "",
        content: null,
        fileUrl: ""
    });

    const [procedureModalProperties, setProcedureModalProperties] = useState({
        isOpen: false,
        title: "",
        procedures: []
    });

    const [procedureDetailModalProperties, setProcedureDetailModalProperties] = useState({
        isOpen: false,
        procedure: null
    });

    const handleFileOpen = (fileUrl) => {
        setFileModalProperties({
            isOpen: true,
            title: t("general.fileViewer"),
            content: <CustomFilesViewer fileurls={[fileUrl]} initialPage={1} />,
            fileUrl: fileUrl
        });
    };

    const closeFileModal = () => setFileModalProperties(prev => ({ ...prev, isOpen: false }));

    const handleExport = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', ''); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    const handleProcedureModalOpen = useCallback((cycle) => {
        setCurrentCycle(cycle);
        setProcedureModalProperties({
            isOpen: true,
            title: `${t("pages.workflow.controlsFor")} ${t(cycleNameToKey[cycle.name] || cycle.name)}`,
            procedures: cycle.controls
        });
        if (!cycleId) {
            navigate(`/dataroom/${dataroom_id}/workflow/cycle/${cycle.id}`);
        }
    }, [cycleId, navigate, dataroom_id, t, cycleNameToKey]);

    useEffect(() => {
        if (cycleId && workflowData) {
            const cycle = workflowData.find(c => c.id === parseInt(cycleId));
            if (cycle) {
                handleProcedureModalOpen(cycle);
            }
        }
    }, [cycleId, workflowData, handleProcedureModalOpen]);

    const closeProcedureModal = () =>  {
        setProcedureModalProperties(prev => ({ ...prev, isOpen: false }));
        navigate(`/dataroom/${dataroom_id}/workflow`, { replace: true });
    };

    const handleProcedureDetailOpen = useCallback((procedure) => {
        setProcedureDetailModalProperties({
            isOpen: true,
            procedure: procedure
        });
        if (cycleId) {
            navigate(`/dataroom/${dataroom_id}/workflow/cycle/${cycleId}/control/${procedure.id}`);
        }
    }, [cycleId, navigate, dataroom_id]);

    const closeProcedureDetailModal = useCallback(() => {
        setProcedureDetailModalProperties(prev => ({ ...prev, isOpen: false }));
        navigate(`/dataroom/${dataroom_id}/workflow/cycle/${cycleId}`, { replace: true });
    }, [cycleId, navigate, dataroom_id]);

    useEffect(() => {
        if (cycleId && workflowData) {
            const cycle = workflowData.find(c => c.id === parseInt(cycleId));
            if (cycle) {
                handleProcedureModalOpen(cycle);
                
                if (controlId) {
                    const control = cycle.controls.find(c => c.id === parseInt(controlId));
                    if (control) {
                        handleProcedureDetailOpen(control);
                    }
                }
            }
        }
    }, [cycleId, controlId, workflowData, handleProcedureModalOpen, handleProcedureDetailOpen]);

    const calculateProgress = (controls) => {
        if (!controls || controls.length === 0) return 0;
        const completedControls = controls.filter(control => control.status === "Completed").length;
        return completedControls / controls.length;
    };

    const renderCyclesTable = () => (
        <Card className="cycles-card mb-3">
            <Card.Body>
                <Card.Title className="cycles-title">
                    <i className="bi bi-diagram-3 me-2"></i>{t("pages.workflow.auditCycles")}
                </Card.Title>
                <Table hover responsive className="workflow-table">
                    <thead>
                        <tr>
                            <th>{t("pages.workflow.cycleName")}</th>
                            <th>{t("pages.workflow.controlsCount")}</th>
                            <th>{t("pages.workflow.controlsProgress")}</th>
                            <th>{t("pages.workflow.risks")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {workflowData.map(cycle => {
                            const progress = calculateProgress(cycle.controls);
                            const hasRisks = cycle.cycle_risks && cycle.cycle_risks.length > 0;
                            
                            return (
                                <tr key={cycle.id} onClick={() => handleProcedureModalOpen(cycle)} className="cycle-row">
                                    <td>
                                        <i className="bi bi-folder2-open me-2"></i>
                                        {t(cycleNameToKey[cycle.name] || cycle.name)}
                                    </td>
                                    <td>
                                        <i className="bi bi-list-check me-2"></i>
                                        {cycle.controls.length}
                                    </td>
                                    <td>
                                        <div className="progress">
                                            <div 
                                                className="progress-bar" 
                                                role="progressbar" 
                                                style={{width: `${progress * 100}%`}}
                                                aria-valuenow={progress * 100} 
                                                aria-valuemin="0" 
                                                aria-valuemax="100"
                                            >
                                                {Math.round(progress * 100)}%
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <Badge bg={hasRisks ? 'warning' : 'success'}>
                                            <i className={`bi ${hasRisks ? 'bi-exclamation-triangle' : 'bi-check'} me-1`}></i>
                                            {hasRisks ? `${cycle.cycle_risks.length} ${t("pages.workflow.risks")}` : t("pages.workflow.noRisks")}
                                        </Badge>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );

    return (
        <LayoutPageHeader title={t('pages.workflow.title')}>
            {isLoading ? (
                <div className="loading-spinner">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">{t("loading")}</span>
                    </div>
                </div>
            ) : error ? (
                <div className="alert alert-danger" role="alert">
                    <i className="bi bi-exclamation-triangle-fill me-2"></i>
                    {error}
                </div>
            ) : (
                <>
                    <AuditOverview 
                        dataroomId={dataroom_id}
                        token={token}
                        workflowData={workflowData}
                        auditData={auditData}
                        preparingAudit={preparingAudit}
                        prepareError={prepareError}
                        prepareAudit={prepareAudit}
                        preparationStatus={preparationStatus}
                        onRefetch={refetchAll}
                        onFileOpen={handleFileOpen}
                    />
                    {renderCyclesTable()}
                </>
            )}
    
            <GenericModal 
                show={fileModalProperties.isOpen}
                onHide={closeFileModal}
                title={
                    <div className="d-flex align-items-center gap-3">
                        {fileModalProperties.title}
                        <Button 
                            variant="outline-primary"
                            onClick={() => handleExport(fileModalProperties.fileUrl)}
                            className="d-flex align-items-center gap-2"
                            size="sm"
                        >
                            <i className="bi bi-download"></i>
                            {t("general.download")}
                        </Button>
                    </div>
                }
                fullscreen={true}
                centered={false}
                no_padding={true}
            >
                {fileModalProperties.content}
            </GenericModal>

            <Modal 
                show={procedureModalProperties.isOpen} 
                onHide={closeProcedureModal}
                fullscreen
                className="procedure-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="bi bi-list-ul me-2"></i>
                        {procedureModalProperties.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProcedureList 
                        procedures={procedureModalProperties.procedures} 
                        onProcedureClick={handleProcedureDetailOpen}
                        cycleName={currentCycle ? t(cycleNameToKey[currentCycle.name] || currentCycle.name) : ''}
                        supervisor={currentCycle?.cycle_supervisor_username || t("general.notAssigned")}
                        auditor={currentCycle?.cycle_auditor_username || t("general.notAssigned")}
                        risks={currentCycle?.cycle_risks || []}
                        priorityNameToKey={priorityNameToKey}
                        statusNameToKey={statusNameToKey}
                    />
                </Modal.Body>
            </Modal>

            <Modal 
                show={procedureDetailModalProperties.isOpen} 
                onHide={closeProcedureDetailModal}
                fullscreen
                className="procedure-detail-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="bi bi-clipboard-data me-2"></i>
                        {t("pages.workflow.controlDetails")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {procedureDetailModalProperties.procedure && currentCycle && (
                        <ProcedureDetail 
                            procedure={procedureDetailModalProperties.procedure}
                            dataroomId={dataroom_id}
                            onFileOpen={handleFileOpen}
                            refetch={refetchAll}
                            priorityNameToKey={priorityNameToKey}
                            statusNameToKey={statusNameToKey}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </LayoutPageHeader>
    );
}

export default Workflow;