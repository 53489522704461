import { useState, useCallback, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAudit = (token, dataroomId) => {
    const [auditData, setAuditData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [preparingAudit, setPreparingAudit] = useState(false);
    const [prepareError, setPrepareError] = useState(null);
    const [preparationStatus, setPreparationStatus] = useState(null);

    const fetchAudit = useCallback(async () => {
        if (!token) return;

        setLoading(true);
        setError(null);

        try {
            const response = await api.get(
                API_ROUTES.GET_AUDIT(dataroomId),
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setAuditData(response.data.audit);
            setLoading(false);
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while fetching the audit data');
            setLoading(false);
        }
    }, [token, dataroomId]);

    const prepareAudit = useCallback(() => {
        return new Promise((resolve, reject) => {
            setPreparingAudit(true);
            setPrepareError(null);

            // Construire l'URL pour l'EventSource
            const eventSourceUrl = `${process.env.REACT_APP_API_URL}/dataroom/${dataroomId}/prepare-audit?token=${encodeURIComponent(token)}`;
            console.log('Starting audit preparation:', eventSourceUrl);

            const eventSource = new EventSource(eventSourceUrl);

            eventSource.onopen = (event) => {
                console.log('EventSource connection opened:', event);
            };

            eventSource.onmessage = (event) => {
                console.log('Raw SSE data received:', event.data);
                try {
                    const data = JSON.parse(event.data);
                    console.log('Parsed SSE data:', data);

                    if (data.error) {
                        console.error('Error in SSE data:', data.error);
                        eventSource.close();
                        setPrepareError(data.error);
                        setPreparingAudit(false);
                        reject(new Error(data.error));
                        return;
                    }

                    setPreparationStatus(data);

                    if (data.step === 'completion' && data.status === 'completed') {
                        console.log('Audit preparation completed');
                        eventSource.close();
                        setPreparingAudit(false);
                        resolve(true);
                    }
                } catch (error) {
                    console.error('Error parsing SSE data:', error);
                    eventSource.close();
                    setPrepareError('Error parsing server response');
                    setPreparingAudit(false);
                    reject(error);
                }
            };

            eventSource.onerror = (err) => {
                console.error('SSE Error:', err);
                console.log('EventSource readyState:', eventSource.readyState);
                eventSource.close();
                setPrepareError('Connection to server lost');
                setPreparingAudit(false);
                reject(new Error('Connection to server lost'));
            };
        });
    }, [token, dataroomId]);

    useEffect(() => {
        fetchAudit();
    }, [fetchAudit]);

    return {
        auditData,
        loading,
        error,
        preparingAudit,
        prepareError,
        prepareAudit,
        preparationStatus,
        refetch: fetchAudit
    };
};