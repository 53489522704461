import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Table, Button, Spinner, Alert, Image, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatDate } from '../../utils/Date';
import UserEditModal from './UserEditModal';
import { useUpdateUser } from '../../hooks/admin/useUpdateUser';
import { useAddUser } from '../../hooks/admin/useAddUser';
import { useDeactivateUser } from '../../hooks/admin/useDeactivateUser';
import { useActivateUser } from '../../hooks/admin/useActivateUser';

const UserManagement = ({ users, loading, error, token, setUsers }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { updateUser, updating, updateError } = useUpdateUser(token);
  const { addUser, adding, addError } = useAddUser(token);
  const { deactivateUser, deactivating } = useDeactivateUser(token);
  const { activateUser, activating } = useActivateUser(token);

  const handleShowModal = (user = null) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const handleSaveUser = async (userData) => {
    try {
      if (selectedUser) {
        const updatedUser = await updateUser({ ...userData, user_id: selectedUser.user_id });
        setUsers(users.map(user => user.user_id === updatedUser.user_id ? updatedUser : user));
      } else {
        const newUser = await addUser(userData);
        setUsers([...users, newUser]);
      }
      handleCloseModal();
    } catch (error) {
      console.error("Failed to save user:", error);
    }
  };

  const handleToggleUserStatus = async (user) => {
    try {
      if (user.status === 'active') {
        await deactivateUser(user.user_id);
      } else {
        await activateUser(user.user_id);
      }
      const updatedUsers = users.map(u => 
        u.user_id === user.user_id 
          ? { ...u, status: u.status === 'active' ? 'inactive' : 'active' } 
          : u
      );
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Failed to toggle user status:", error);
    }
  };

  const StatusBadge = ({ status }) => (
    <Badge bg={status === 'active' ? 'success' : 'secondary'} style={{ fontSize: '0.8em' }}>
      {status === 'active' ? 'Active' : 'Inactive'}
    </Badge>
  );

  return (
    <Card className="admin_card shadow-sm">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>User Management</h3>
          <Button variant="primary" onClick={() => handleShowModal()}>
            + Add User
          </Button>
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <Table hover responsive className="admin_table-nowrap">
            <thead>
              <tr>
                <th>User</th>
                <th>Name</th>
                <th>Email</th>
                <th>Company</th>
                <th>Last Connection</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.user_id}>
                  <td>
                    <div className="d-flex align-items-center">
                      <span>{user.user_id}</span>
                    </div>
                  </td><td>
                    <div className="d-flex align-items-center">
                      <Image src={user.picture_url} roundedCircle width={40} height={40} className="me-2" />
                      <span>{user.username}</span>
                    </div>
                  </td>
                  <td>{user.email}</td>
                  <td>
                    {user.company_name}
                    {user.company_url && (
                      <a href={user.company_url} target="_blank" rel="noopener noreferrer" className="ms-2">
                        <i className="bi bi-link-45deg"></i>
                      </a>
                    )}
                  </td>
                  <td>{user.last_connection ? formatDate(user.last_connection, t) : 'Never'}</td>
                  <td><StatusBadge status={user.status} /></td>
                  <td>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Edit user</Tooltip>}
                    >
                      <Button variant="outline-secondary" size="sm" className="me-2" onClick={() => handleShowModal(user)}>
                        <i className="bi bi-pencil"></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{user.status === 'active' ? 'Deactivate user' : 'Activate user'}</Tooltip>}
                    >
                      <Button 
                        variant={user.status === 'active' ? 'outline-danger' : 'outline-success'} 
                        size="sm" 
                        onClick={() => handleToggleUserStatus(user)}
                        disabled={deactivating || activating}
                      >
                        <i className={`bi bi-${user.status === 'active' ? 'person-x' : 'person-check'}`}></i>
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>

      <UserEditModal
        show={showModal}
        handleClose={handleCloseModal}
        user={selectedUser}
        onSave={handleSaveUser}
        isProcessing={updating || adding}
        error={updateError || addError}
      />
    </Card>
  );
};

export default UserManagement;