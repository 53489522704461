import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../general/UserProvider';
import { DataRoomContext } from '../DisplayDataRoom';
import LayoutPageHeader from '../../general/LayoutPageHeader';
import GenericModal from '../../general/GenericModal';
import IRRow from './table/IRRow';
import TableHeader from './table/TableHeader';
import AddTask from './AddTask';
import IRDetails from './IRDetails';
import { useCreateInformationRequest } from '../../hooks/ir/useCreateInformationRequest';
import { useDeleteInformationRequest } from '../../hooks/ir/useDeleteInformationRequest';
import { useFetchIRLData } from '../../hooks/ir/useFetchIRLData';
import { useFetchIRStatuses } from '../../hooks/ir/useFetchIRStatuses';
import { useHandleIRStatusChange } from '../../hooks/ir/useHandleIRStatusChange';
import { useSortRequests } from '../../hooks/ir/useSortRequests';
import { usePermissions } from '../../hooks/permissions/useUserPermissions';
import { useFolders } from '../../hooks/documents/useFolders';
import { useDataroomUsers } from '../../hooks/users/useDataroomUsers';
import './IRL.css';

function IRL() {
    const { t } = useTranslation();
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);
    const { permissions } = usePermissions();
    const navigate = useNavigate();
    const { irId } = useParams();

    const { requests, loading: requestsLoading, error: requestsError, setRequests } = useFetchIRLData(token, dataroom_id);
    const { statuses, loading: statusLoading, error: statusError } = useFetchIRStatuses(token);
    const { folders, loading: foldersLoading, error: foldersError } = useFolders(token, dataroom_id, false, null, 'ir', 'Create');
    const { users, error: usersError, loading: usersLoading } = useDataroomUsers(token, dataroom_id);

    const createRequest = useCreateInformationRequest(token, setRequests);
    const { handleFieldChange } = useHandleIRStatusChange({ dataroom_id, token, requests, setRequests });
    const { sortedRequests, handleSort, sortField, sortDirection } = useSortRequests(requests);
    const [modalState, setModalState] = useState({
        title: "",
        isOpen: false,
        content: null,
        fullscreen: false,
        centered: true
    });

    const isLoading = requestsLoading || statusLoading || foldersLoading || usersLoading;
    const errors = [requestsError, statusError, foldersError, usersError].filter(Boolean);
    const hasError = errors.length > 0;

    const handleFieldUpdate = useCallback(async (item, field, value) => {
        try {
            const response = await handleFieldChange(item, field, value);
            
            if (!response?.data) {
                throw new Error('Invalid response from server');
            }
    
            const updatedIR = response.data;
    
            setRequests(prevRequests => {
                const newRequests = prevRequests.map(request => 
                    request.irl_id === item.irl_id ? updatedIR : request
                );
                
                setModalState(prevState => ({
                    ...prevState,
                    content: (
                        <IRDetails 
                            ir={updatedIR}
                            folders={folders}
                            statuses={statuses}
                            users={users}
                            onFieldUpdate={handleFieldUpdate}
                            requests={newRequests}
                            setRequests={setRequests}
                        />
                    )
                }));
    
                return newRequests;
            });
    
        } catch (error) {
            console.error(`Failed to update ${field}:`, error);
        }
    }, [handleFieldChange, folders, statuses, users, setRequests]);

    const closeModal = useCallback(() => {
        // Réinitialiser complètement l'état de la modal
        setModalState({
            title: "",
            content: null,
            isOpen: false,
            fullscreen: false,
            centered: true
        });
        
        // Mise à jour de l'URL si nécessaire
        if (irId) {
            navigate(`/dataroom/${dataroom_id}/irl`, {
                replace: true
            });
        }
    }, [dataroom_id, irId, navigate]);

    useEffect(() => {
        // On garde une référence à l'état actuel
        const currentTitle = modalState.title;
    
        // On met cela dans un setTimeout pour briser la boucle de mise à jour synchrone
        const timeoutId = setTimeout(() => {
            // Si on n'a pas d'irId dans l'URL mais que la modal montre des détails d'IR
            if (!irId && currentTitle === t('pages.qa.irDetails')) {
                setModalState(prevState => {
                    // Ne mettre à jour que si nécessaire
                    if (prevState.isOpen) {
                        return {
                            ...prevState,
                            isOpen: false
                        };
                    }
                    return prevState;
                });
            }
            // Si on a un irId, on ouvre la modal avec les détails
            else if (irId && requests.length > 0) {
                const ir = requests.find(r => r.irl_id === parseInt(irId, 10));
                if (ir) {
                    setModalState(prevState => {
                        // Ne mettre à jour que si nécessaire
                        if (!prevState.isOpen || prevState.title !== t('pages.qa.irDetails')) {
                            return {
                                title: t('pages.qa.irDetails'),
                                isOpen: true,
                                content: (
                                    <IRDetails 
                                        ir={ir}
                                        folders={folders}
                                        statuses={statuses}
                                        users={users}
                                        onFieldUpdate={handleFieldUpdate}
                                        requests={requests}
                                        setRequests={setRequests}
                                    />
                                ),
                                fullscreen: true,
                                centered: true
                            };
                        }
                        return prevState;
                    });
                }
            }
        }, 0);
    
        // Nettoyage
        return () => clearTimeout(timeoutId);
    }, [irId, requests, folders, statuses, users, handleFieldUpdate, setRequests, modalState.title, t]);

    const deleteRequest = useDeleteInformationRequest(token, setRequests, closeModal);

    const handleAddRequest = async (requestData) => {
        const requestBody = {
            folder_id: requestData.folder_id,
            dataroom_id: parseInt(dataroom_id, 10),
            title: requestData.title,
            description: requestData.description,
            deadline: requestData.deadline,
            assignee: requestData.assignee
        };

        try {
            await createRequest(dataroom_id, requestBody);
            closeModal();
        } catch (error) {
            console.error("Error in creating request", error);
        }
    };

    async function submitDelete(itemToDelete) {
        await deleteRequest(itemToDelete.irl_id);
    }

    const handleRowClick = (ir) => {
        // Navigation vers l'URL avec l'ID de l'IR au lieu d'ouvrir directement la modale
        navigate(`/dataroom/${dataroom_id}/irl/${ir.irl_id}`);
    };

    const actionButtons = (
        <AddTask
            handleAddRequest={handleAddRequest}
            permissions={permissions}
            setModalState={setModalState}
            folders={folders}
            users={users}
        />
    );

    return (
        <LayoutPageHeader title={t('pages.qa.title')} actions={actionButtons}>
            {hasError && (
                <div className="error-container">
                    {errors.map((error, index) => (
                        <p key={index} className="text-danger">{error}</p>
                    ))}
                </div>
            )}
            {isLoading ? (
                <div className="loading-spinner">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">{t('loading')}</span>
                    </div>
                </div>
            ) : (
                <div className="irl-table-wrapper">
                    <div className="irl-table-container">
                        <Table responsive className="irl-table">
                            <TableHeader 
                                sortField={sortField} 
                                sortDirection={sortDirection} 
                                handleSort={handleSort} 
                            />
                            <tbody>
                                {sortedRequests.map((item) => (
                                    <IRRow
                                        key={item.irl_id}
                                        item={item}
                                        setModalState={setModalState}
                                        closeModal={closeModal}
                                        submitDelete={submitDelete}
                                        dataroom_id={dataroom_id}
                                        onRowClick={handleRowClick}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
            <GenericModal
                title={modalState.title}
                show={modalState.isOpen}
                onHide={closeModal}
                fullscreen={modalState.fullscreen}
                centered={modalState.centered}
            >
                {modalState.content}
            </GenericModal>
        </LayoutPageHeader>
    );
}

export default IRL;