import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useProcedureExecution } from '../../../hooks/workflow/useProcedureExecution';
import ModalStartProcedure from './ModalStartProcedure';

function ProcedureStatus({ 
    status,
    onUpdateStatus,
    pendingDocuments,
    token,
    dataroomId,
    procedureId,
    refetch,
    setFindings,
    setConclusion,
    setAssessment,
    statusNameToKey
}) {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const {
        executionStatus,
        executing,
        error,
        progress,
        startExecution
    } = useProcedureExecution(token, dataroomId, procedureId, {
        setFindings,
        setConclusion,
        setAssessment
    });

    const handleStartProcedure = async () => {
        setShowModal(true);
        try {
            await onUpdateStatus('In Progress'); 
            await startExecution();
        } catch (error) {
            console.error('Error executing procedure:', error);
        }
    };

    const handleModalComplete = async () => {
        setShowModal(false);
        if (refetch) {
            await refetch();
        }
    };

    const getStatusInfo = (status, hasPendingDocuments) => {
        const statusStyles = {
            'completed': { color: '#27ae60', icon: 'check-circle-fill' },
            'in progress': { color: '#3498db', icon: 'play-circle-fill' },
            'review': { color: '#f39c12', icon: 'eye-fill' },
            'ready': { color: '#2980b9', icon: 'arrow-right-circle-fill' },
            'missing documents': { 
                color: '#e74c3c', 
                icon: hasPendingDocuments ? 'exclamation-circle-fill' : 'question-circle-fill' 
            }
        };
        return statusStyles[status.toLowerCase()] || { color: '#95a5a6', icon: 'question-circle-fill' };
    };

    const getStatusActions = (currentStatus) => {
        const statusFlow = {
            'completed': [
                { 
                    next: 'In Progress', 
                    text: t("pages.workflow.reopen"),
                    icon: 'arrow-clockwise',
                    disabled: false
                }
            ],
            'review': [
                {
                    next: 'In Progress',
                    text: t("pages.workflow.reopen"),
                    icon: 'arrow-clockwise',
                    disabled: false
                },
                { 
                    next: 'Completed', 
                    text: t("pages.workflow.completeReview"),
                    icon: 'check2-circle',
                    disabled: false
                }
            ],
            'in progress': [
                {
                    next: 'Missing Documents',
                    text: t("pages.workflow.previous"),
                    icon: 'arrow-left',
                    disabled: false,
                    style: { backgroundColor: getStatusInfo('missing documents', pendingDocuments > 0).color }
                },
                { 
                    next: 'Review', 
                    text: t("pages.workflow.sendToReview"),
                    icon: 'eye-fill',
                    disabled: false,
                    style: { backgroundColor: getStatusInfo('review').color }
                }
            ],
            'missing documents': [
                { 
                    next: 'In Progress', 
                    text: t("pages.workflow.startProcedure"),
                    icon: 'play-fill',
                    disabled: pendingDocuments > 0
                }
            ]
        };
        return statusFlow[currentStatus.toLowerCase()] || [];
    };

    const handleStatusChange = async (newStatus) => {
        if (newStatus === 'In Progress' && status === 'Missing Documents') {
            handleStartProcedure();
            return;
        }
        
        await onUpdateStatus(newStatus);
    };

    const statusInfo = getStatusInfo(status, pendingDocuments > 0);

    return (
        <>
            <div className="status-section">
                <h3 className="section-title-right">{t("pages.workflow.status")}</h3>
                <div className="status-container d-flex w-100">
                    <div className="status-column" style={{ width: '200px' }}>
                        <div className="current-status d-flex align-items-center h-100">
                            <span className="status-value" style={{ color: statusInfo.color }}>
                                <i className={`bi bi-${statusInfo.icon} me-2`}></i>
                                {t(statusNameToKey[status] || status)}
                            </span>
                        </div>
                    </div>
                    
                    <div className="d-flex flex-grow-1 justify-content-end">
                        <div className="status-actions d-flex gap-2">
                            {getStatusActions(status).map((action, index) => (
                                <Button
                                    key={action.next}
                                    onClick={() => handleStatusChange(action.next)}
                                    className="btn btn-sm status-action-button text-nowrap"
                                    style={{
                                        ...action.style,
                                        backgroundColor: action.style?.backgroundColor || 
                                            (action.disabled ? '#6c757d' : getStatusInfo(action.next.toLowerCase(), pendingDocuments > 0).color),
                                        border: 'none',
                                        color: 'white',
                                        padding: '0.375rem 0.5rem',
                                        minWidth: 'fit-content',
                                        opacity: action.disabled ? 0.65 : 1,
                                        cursor: action.disabled ? 'not-allowed' : 'pointer'
                                    }}
                                    disabled={action.disabled}
                                    title={action.text}
                                >
                                    <i className={`bi bi-${action.icon} me-1`}></i>
                                    {action.text}
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <ModalStartProcedure 
                show={showModal}
                onClose={() => setShowModal(false)}
                onComplete={handleModalComplete}
                executing={executing}
                executionStatus={executionStatus}
                error={error}
                progress={progress}
            />
        </>
    );
}

export default ProcedureStatus;