// hooks/admin/requiredDocumentsHooks.js
import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddRequiredDocument = (token) => {
    const [adding, setAdding] = useState(false);
    const [addError, setAddError] = useState(null);

    const addRequiredDocument = async (data) => {
        setAdding(true);
        setAddError(null);
        try {
            const response = await api.post(
                API_ROUTES.ADMIN_ADD_REQUIRED_DOCUMENT,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setAdding(false);
            return response.data;
        } catch (err) {
            setAddError(err.message || 'Error adding required document');
            setAdding(false);
            return null;
        }
    };

    return { addRequiredDocument, adding, addError };
};

export const useDeleteRequiredDocument = (token) => {
    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const deleteRequiredDocument = async (documentId) => {
        setDeleting(true);
        setDeleteError(null);
        try {
            await api.delete(
                `${API_ROUTES.ADMIN_DELETE_REQUIRED_DOCUMENT}/${documentId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setDeleting(false);
            return true;
        } catch (err) {
            setDeleteError(err.message || 'Error deleting required document');
            setDeleting(false);
            return false;
        }
    };

    return { deleteRequiredDocument, deleting, deleteError };
};

export const useControlDocumentLink = (token) => {
    const [linking, setLinking] = useState(false);
    const [unlinking, setUnlinking] = useState(false);
    const [linkError, setLinkError] = useState(null);

    const addControlDocument = async (controlId, documentId) => {
        setLinking(true);
        setLinkError(null);
        try {
            const response = await api.post(
                `${API_ROUTES.ADMIN_CONTROL_DOCUMENTS}/${controlId}/documents`,
                { document_requirement_id: documentId }, // Envoi direct de l'ID
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setLinking(false);
            return response.data;
        } catch (err) {
            setLinkError(err.message || 'Error linking document to control');
            setLinking(false);
            return null;
        }
    };

    const deleteControlDocument = async (controlId, requirementId) => {
        setUnlinking(true);
        setLinkError(null);
        try {
            await api.delete(
                `${API_ROUTES.ADMIN_CONTROL_DOCUMENTS}/${controlId}/documents/${requirementId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setUnlinking(false);
            return true;
        } catch (err) {
            setLinkError(err.message || 'Error unlinking document from control');
            setUnlinking(false);
            return false;
        }
    };

    return {
        addControlDocument,
        deleteControlDocument,
        linking,
        unlinking,
        linkError
    };
};

export const useRequiredDocumentsByControl = (token, controlId) => {
    const [requiredDocuments, setRequiredDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchRequiredDocuments = useCallback(async () => {
        if (!token || !controlId) return;

        setLoading(true);
        setError(null);
        try {
            const response = await api.get(
                API_ROUTES.ADMIN_GET_REQUIRED_DOCUMENTS(controlId),
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setRequiredDocuments(response.data);
        } catch (error) {
            setError(error.message || "Error fetching required documents");
        } finally {
            setLoading(false);
        }
    }, [token, controlId]);

    useEffect(() => {
        fetchRequiredDocuments();
    }, [fetchRequiredDocuments]);

    return {
        requiredDocuments,
        setRequiredDocuments,
        loading,
        error,
        refetch: fetchRequiredDocuments
    };
};

export const useFetchAllRequiredDocuments = (token) => {
    const [requiredDocuments, setRequiredDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchRequiredDocuments = useCallback(async () => {
        if (!token) return;

        setLoading(true);
        setError(null);
        try {
            const response = await api.get(
                API_ROUTES.ADMIN_GET_ALL_REQUIRED_DOCUMENTS,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setRequiredDocuments(response.data);
        } catch (error) {
            setError(error.message || "Error fetching required documents");
        } finally {
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        fetchRequiredDocuments();
    }, [fetchRequiredDocuments]);

    return { 
        requiredDocuments, 
        setRequiredDocuments, 
        loading, 
        error, 
        refetch: fetchRequiredDocuments 
    };
};