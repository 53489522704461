import React from 'react';
import { useTranslation } from 'react-i18next';
import SortableHeader from '../SortableHeader';

function TableHeader({ sortField, sortDirection, handleSort }) {
    const { t } = useTranslation();
    return (
        <thead>
            <tr>
                <SortableHeader 
                    label={t('pages.qa.tableFolder')}
                    field="folder_hierarchy"
                    currentSortField={sortField}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                />
                <th>{t('pages.qa.tableTitle')}</th>
                <SortableHeader 
                    label={t('pages.qa.tableStatus')}
                    field="status"
                    currentSortField={sortField}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                />
                <SortableHeader 
                    label={t('pages.qa.tableCreatedBy')}
                    field="creator"
                    currentSortField={sortField}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                />
                <SortableHeader 
                    label={t('pages.qa.tableAssignee')}
                    field="assignee"
                    currentSortField={sortField}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                />
                <SortableHeader 
                    label={t('pages.qa.tableDeadline')}
                    field="deadline"
                    currentSortField={sortField}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                />
                <th></th>
            </tr>
        </thead>
    );
};

export default TableHeader;