import React, { useState } from 'react';
import { Card, Row, Col, Badge, Button } from 'react-bootstrap';
import UploadSpecificDocumentModal from '../../workflow/processes/UploadSpecificDocumentModal';

const DOCUMENT_STATUS = {
    created: { variant: 'secondary', label: 'Created' },
    sent: { variant: 'primary', label: 'Sent' },
    completed: { variant: 'success', label: 'Completed' },
    rejected: { variant: 'danger', label: 'Rejected' }
};

const DocumentRequestDetails = ({ request, token, dataroomId, onRefetchData }) => {
    const [showUploadModal, setShowUploadModal] = useState(false);

    const formatDate = (dateString) => {
        return dateString ? new Date(dateString).toLocaleDateString() : '-';
    };

    const getStatusBadge = (status) => {
        const statusConfig = DOCUMENT_STATUS[status] || { variant: 'secondary', label: status };
        return <Badge bg={statusConfig.variant}>{statusConfig.label}</Badge>;
    };

    const handleUploadClick = () => {
        setShowUploadModal(true);
    };

    const handleUploadComplete = (response) => {
        onRefetchData?.();
    };

    const renderUploadSection = () => {
        if (request.uploaded_at && request.uploaded_file) {
            return (
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={3} className="text-muted">Uploaded by</Col>
                            <Col>{request.uploaded_by.name}</Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={3} className="text-muted">Uploaded at</Col>
                            <Col>{formatDate(request.uploaded_at)}</Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={3} className="text-muted">Document</Col>
                            <Col>
                                <a 
                                    href={request.uploaded_file.url}
                                    className="text-decoration-none"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="bi bi-file-earmark-text me-2"></i>
                                    {request.uploaded_file.name}
                                </a>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            );
        }

        return (
            <Card>
                <Card.Body className="text-center py-4">
                    <i className="bi bi-cloud-upload display-4 text-muted mb-3 d-block"></i>
                    <p className="text-muted mb-3">No document has been uploaded yet</p>
                    <Button 
                        variant="primary"
                        onClick={handleUploadClick}
                    >
                        <i className="bi bi-upload me-2"></i>
                        Upload Document
                    </Button>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <div className="p-3">
                <Row className="mb-4">
                    <Col>
                        <h5 className="mb-3">Control Information</h5>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={3} className="text-muted">Title</Col>
                                    <Col>{request.title}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={3} className="text-muted">Associated Controls</Col>
                                    <Col>{request.control}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={3} className="text-muted">Document Type</Col>
                                    <Col>{request.document_type}</Col>
                                </Row>
                                {request.description && (
                                    <>
                                        <hr />
                                        <Row>
                                            <Col md={3} className="text-muted">Description</Col>
                                            <Col>{request.description}</Col>
                                        </Row>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col>
                        <h5 className="mb-3">Request Status</h5>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={3} className="text-muted">Status</Col>
                                    <Col>{getStatusBadge(request.status)}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={3} className="text-muted">Assigned to</Col>
                                    <Col>{request.assigned_to.name}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={3} className="text-muted">Created by</Col>
                                    <Col>{request.created_by.name}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={3} className="text-muted">Created at</Col>
                                    <Col>{formatDate(request.creation_date)}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h5 className="mb-3">
                            {request.uploaded_at ? 'Upload Information' : 'Document Upload'}
                        </h5>
                        {renderUploadSection()}
                    </Col>
                </Row>
            </div>

            <UploadSpecificDocumentModal
                show={showUploadModal}
                onClose={() => setShowUploadModal(false)}
                documentInfo={{
                    name: request.title,
                    description: request.description,
                    id: request.id,
                    requestedDocumentId: request.id,
                    folder: -1,
                }}
                onUpload={handleUploadComplete}
                token={token}
                dataroomId={dataroomId}
                onRefetchData={onRefetchData}
            />
        </>
    );
};

export default DocumentRequestDetails;