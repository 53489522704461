import React from 'react';
import { Nav } from 'react-bootstrap';

const RequestsSubMenu = ({ activeTab, onTabChange }) => {
    return (
        <Nav variant="tabs" className="mb-3">
            <Nav.Item>
                <Nav.Link 
                    active={activeTab === 'documents'}
                    onClick={() => onTabChange('documents')}
                >
                    Documents
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link 
                    active={activeTab === 'questions'}
                    onClick={() => onTabChange('questions')}
                >
                    Questions
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
};

export default RequestsSubMenu;