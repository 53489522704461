import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { Dropdown } from "react-bootstrap";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ActivitiesList from '../../general/ActivitiesList';
import DeleteForm from '../DeleteForm';
import RenameForm from '../RenameForm';

function FileActions({ item, handleComments, setModalProperties, updateFile, refetchFiles, currentFolder, deleteFile, extractedAttributes }) {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const toggleRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (show && toggleRef.current) {
            const rect = toggleRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                right: window.innerWidth - rect.right + 160,
            });
        }
    }, [show]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
                toggleRef.current && !toggleRef.current.contains(event.target)) {
                setShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleEditClick = (item) => {
        setModalProperties(prevProps => ({
            ...prevProps,
            title: t('pages.documents.rename'),
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false,
            content: <RenameForm 
                item={item} 
                setModalProperties={setModalProperties} 
                submitAction={submitFileUpdate} 
            />
        }));
        setShow(false);
    };
    
    const handleDL = (item) => {
        console.log(item);
        setShow(false);
    };

    const submitFileUpdate = async (file_id, updatedFileData) => {
        const { localName } = updatedFileData;
        const updatedFileDataToSend = { "name": localName };
        try {
            await updateFile(file_id, updatedFileDataToSend);
            refetchFiles(currentFolder.id);
            setModalProperties(prevProps => ({ ...prevProps, isOpen: false }));
        } catch (error) {
            console.error("Error updating file:", error);
        }
    };

    const handleSummary = (item) => {
        const summary = item.summary ? item.summary : t('pages.documents.noSummaryAvailable');
        setModalProperties(prevProps => ({
            ...prevProps,
            title: t('pages.documents.summary'),
            content: <ReactMarkdown remarkPlugins={[remarkGfm]}>{summary}</ReactMarkdown>,
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false
        }));
        setShow(false);
    };

    const handleViewExtractedAttributes = () => {
        setModalProperties(prevProps => ({
            ...prevProps,
            title: t('pages.documents.extractedAttributes'),
            content: (
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                            <th>Page</th>
                            <th>Justification</th>
                        </tr>
                    </thead>
                    <tbody>
                    {extractedAttributes.map((attr) => (
                        <tr key={attr.id}>
                            <td>{attr.attribute_name}</td>
                            <td>{attr.value}</td>
                            <td>{attr.page_number || 'N/A'}</td>
                            <td>{attr.justification || 'N/A'}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            ),
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false
        }));
        setShow(false);
    };
    
    const handleActivities = (item) => {
        setModalProperties(prevProps => ({
            ...prevProps,
            title: t('pages.activities.title'),
            content: <ActivitiesList object_type="FILE" object_id={item.id} />,
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false
        }));
        setShow(false);
    };
    
    const handleDelete = () => {
        setModalProperties(prevProps => ({
            ...prevProps,
            title: t('pages.documents.deleteFile'),
            content: <DeleteForm 
                item={item} 
                setModalProperties={setModalProperties} 
                submitDelete={() => handleDeleteFile(item.id)}
            />,
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false
        }));
        setShow(false);
    };

    const handleDeleteFile = async (file_id) => {
        try {
            await deleteFile(file_id);
            setModalProperties(prevProps => ({ ...prevProps, isOpen: false }));
            refetchFiles(currentFolder.id);
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };

    const renderDropdown = () => (
        <div 
            ref={dropdownRef}
            style={{
                position: 'absolute',
                top: `${dropdownPosition.top}px`,
                right: `${dropdownPosition.right}px`,
                zIndex: 1050,
            }}
        >
            <Dropdown.Menu show={true} align="end">
                <Dropdown.Item onClick={() => { handleComments(item); setShow(false); }}>
                    <i className="bi bi-chat-left-dots icon-spacing"></i> {t('comment.title')}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleEditClick(item)}>
                    <i className="bi bi-pencil icon-spacing"></i> {t('pages.documents.rename')}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => { handleActivities(item); setShow(false); }}>
                    <i className="bi bi-clock-history icon-spacing"></i> {t('pages.activities.title')}
                </Dropdown.Item>
                {item.summary && (
                    <Dropdown.Item onClick={() => { handleSummary(item); setShow(false); }}>
                        <i className="bi bi-card-text icon-spacing"></i> {t('pages.documents.summary')}
                    </Dropdown.Item>
                )}
                {extractedAttributes && extractedAttributes.length > 0 && (
                    <Dropdown.Item onClick={() => { handleViewExtractedAttributes(); setShow(false); }}>
                        <i className="bi bi-info-square icon-spacing"></i> {t('pages.documents.extractedAttributes')}
                    </Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleDL(item)}>
                    <i className="bi bi-download icon-spacing"></i> {t('general.download')}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => { handleDelete(); setShow(false); }}>
                    <i className="bi bi-trash3 icon-spacing"></i> {t('general.delete')}
                </Dropdown.Item>
            </Dropdown.Menu>
        </div>
    );

    return (
        <>
            <span
                ref={toggleRef}
                onClick={() => setShow(!show)}
                className="action-icon cursorHover no-arrow"
            >
                <i className="bi bi-three-dots"></i>
            </span>
            {show && ReactDOM.createPortal(
                renderDropdown(),
                document.body
            )}
        </>
    );
}

export default FileActions;