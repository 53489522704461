import React, { useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../general/UserProvider';
import { DataRoomContext } from '../DisplayDataRoom';
import { useFolders } from '../../hooks/documents/useFolders';
import GenericModal from '../../general/GenericModal';
import WSCreateNoteForm from './WSCreateNoteForm';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';
import './WSCreateNote.css';

function WSCreateNote({ fetchNotes }) {
    const { t } = useTranslation();
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);

    const { folders, loading, error } = useFolders(token, dataroom_id, true, null, 'note', 'Create');

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleCreateNote = () => {
        setModalContent(
            <WSCreateNoteForm 
                createNote={createNote} 
                folders={folders}
                loadingFolders={loading}
                errorFolders={error}
            />
        );
        setShowModal(true);
    };

    const createNote = useCallback(async (title, content, selectedFolders) => {
        if(!token) return;

        try {
            await api.post(API_ROUTES.CREATE_NOTE_IN_DATAROOM(dataroom_id), {
                title,
                content,
                folder_ids: selectedFolders
            });
            fetchNotes();
            setShowModal(false);    
        } catch (error) {
            console.error("Error adding note:", error);
            throw error;
        }
    }, [dataroom_id, token, fetchNotes]);

    return (
        <>
            <button className="btn-action btn-add-note" onClick={handleCreateNote}>
                <i className="bi bi-file-earmark-plus"></i>
                <span>{t('pages.workspace.createNote')}</span>
            </button>

            <GenericModal
                title={t('pages.workspace.createNote')}
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                    setModalContent(null); 
                }}
                fullscreen={true}
                centered={false}
            >
                {modalContent}
            </GenericModal>
        </>
    );
};

export default WSCreateNote;