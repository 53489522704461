import React, { useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DataRoomContext } from '../DisplayDataRoom';
import { Alert } from 'react-bootstrap';
import LayoutPageHeader from '../../general/LayoutPageHeader';
import TeamCard from './TeamCard';
import GenericModal from '../../general/GenericModal';
import { useUser } from '../../general/UserProvider';
import { useFetchTeamData } from '../../hooks/team/useFetchTeamData';
import { usePermissions } from '../../hooks/permissions/useUserPermissions';
import UpdateTeamForm from './UpdateTeamForm';
import { useRoles } from '../../hooks/team/useRoles';
import './Team.css';

function Team() {
    const { t } = useTranslation();
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);
    const { permissions } = usePermissions();
    const canEdit = permissions?.global?.["Team"]?.includes("Update");
    const { team, loading, error, refetchTeamData } = useFetchTeamData(token, dataroom_id);
    const { roles } = useRoles(token, dataroom_id);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleUpdateSuccess = useCallback(async () => {
        await refetchTeamData();
        setShowModal(false);
    }, [refetchTeamData]);

    const openModalWithContent = (member) => {
        setModalContent(
            <UpdateTeamForm
                member={member}
                token={token}
                onUpdateSuccess={handleUpdateSuccess}
                roles={roles}
            />
        );
        setShowModal(true);
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center mt-3">
                    <div className="loading-spinner">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">{t('loading')}</span>
                        </div>
                    </div>
                </div>
            );
        }

        if (error) {
            return (
                <Alert variant="danger" className="mt-3">
                    Error: {error}
                </Alert>
            );
        }

        if (!team || team.length === 0) {
            return (
                <Alert variant="warning" className="mt-3">
                    No team members yet.
                </Alert>
            );
        }

        return (
            <div className="team-cards-container">
                {team.map(member => (
                    <div className="team-card-wrapper" key={member.user_id}>
                        <TeamCard member={member} canEdit={canEdit} onEdit={openModalWithContent} />
                    </div>
                ))}
            </div>
        );
    };
  
    return (
        <LayoutPageHeader title={t('pages.team.title')}>
            <div className="team-container">
                {renderContent()}
            </div>
            <GenericModal
                title="Update Team Member"
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                    setModalContent(null); 
                }}
                fullscreen={true}
                centered={false}
            >
                {modalContent}
            </GenericModal>
        </LayoutPageHeader>
    );
};

export default Team;