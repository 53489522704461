import React from 'react';
import { Table, Form, Badge } from 'react-bootstrap';

const QuestionRequestTable = ({
    requests,
    selectedRequests,
    onSelectionChange,
    filter
}) => {
    const filteredRequests = requests.filter(req => 
        req.subject.toLowerCase().includes(filter.toLowerCase()) ||
        req.control.toLowerCase().includes(filter.toLowerCase())
    );

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            onSelectionChange(new Set(filteredRequests.map(req => req.id)));
        } else {
            onSelectionChange(new Set());
        }
    };

    const handleSelectOne = (id) => {
        const newSelected = new Set(selectedRequests);
        if (newSelected.has(id)) {
            newSelected.delete(id);
        } else {
            newSelected.add(id);
        }
        onSelectionChange(newSelected);
    };

    const getStatusBadge = (status) => {
        const variants = {
            pending: 'warning',
            sent: 'primary',
            answered: 'success'
        };
        
        return (
            <Badge bg={variants[status] || 'secondary'}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
            </Badge>
        );
    };

    const getPriorityBadge = (priority) => {
        const variants = {
            high: 'danger',
            medium: 'warning',
            low: 'info'
        };
        
        return (
            <Badge bg={variants[priority] || 'secondary'}>
                {priority.toUpperCase()}
            </Badge>
        );
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString();
    };

    return (
        <Table hover>
            <thead>
                <tr>
                    <th>
                        <Form.Check
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={selectedRequests.size === filteredRequests.length}
                        />
                    </th>
                    <th>Control</th>
                    <th>Subject</th>
                    <th>Status</th>
                    <th>Priority</th>
                    <th>Request Date</th>
                    <th>Deadline</th>
                </tr>
            </thead>
            <tbody>
                {filteredRequests.map(request => (
                    <tr key={request.id}>
                        <td>
                            <Form.Check
                                type="checkbox"
                                checked={selectedRequests.has(request.id)}
                                onChange={() => handleSelectOne(request.id)}
                            />
                        </td>
                        <td>{request.control}</td>
                        <td>{request.subject}</td>
                        <td>{getStatusBadge(request.status)}</td>
                        <td>{getPriorityBadge(request.priority)}</td>
                        <td>{formatDate(request.requestDate)}</td>
                        <td>{formatDate(request.deadline)}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default QuestionRequestTable;