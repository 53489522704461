import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import FECViewer from '../../../common/fileViewers/FECViewer';
import GenerateDocumentModal from '../processes/GenerateDocumentModal';
import UploadSpecificDocumentModal from '../processes/UploadSpecificDocumentModal';
import { baseDocumentDependencies, useDocumentDependencies, useDocumentCategories } from '../processes/AuditDocumentsConfig';
import './AuditDocumentsMenu.css';

const AuditDocumentsMenu = ({
  dataroomId,
  token,
  onFileOpen,
  onRefetchAudit,
  onGenerateDocument,
  onUploadDocument,
  currentYear,
  hasPreviousYearData,
  documents = {
    auditPlanUrl: '',
    confirmationLetterUrl: '',
    fecUrl: '',
    balanceSheetUrl: '',
    subsidiaryLedgersUrl: '',
    incomeStatementUrl: ''
  }
}) => {
  const { t } = useTranslation();
  const documentDependencies = useDocumentDependencies();
  const documentCategories = useDocumentCategories();
  const [showFECViewer, setShowFECViewer] = useState(false);
  const [generateModal, setGenerateModal] = useState({ show: false, document: null });
  const [uploadModal, setUploadModal] = useState({ show: false, document: null });
  const [exportFEC, setExportFEC] = useState(() => {});

  const enrichedCategories = documentCategories.map(category => ({
    ...category,
    documents: category.documents.map(doc => ({
      ...doc,
      url: documents[`${doc.key}Url`]
    }))
  }));

  const canGenerateDocument = (docType) => {
    const dependency = baseDocumentDependencies[docType];
    if (!dependency) return false;

    const hasRequiredDocs = dependency.requiredDocs.every(
      req => documents[`${req}Url`]
    );

    if (dependency.periodConstraints === 'currentAndPreviousYear') {
      return hasRequiredDocs && hasPreviousYearData;
    }

    return hasRequiredDocs;
  };

  const handleDocumentClick = (doc) => {
    if (doc.url) {
      if (doc.key === 'fec') { 
        setShowFECViewer(true);
      } else {
        onFileOpen(doc.url);
      }
    }
  };

  const handleGenerateClick = (docType) => {
    setGenerateModal({
      show: true,
      document: {
        type: docType,
        ...documentDependencies[docType]
      }
    });
  };

  const handleUploadClick = (doc) => {
    setUploadModal({
      show: true,
      document: {
        type: doc.key,
        name: doc.name,
        folder: doc.folder,
        description: doc.description
      }
    });
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="primary" className="documents-dropdown">
          <i className="bi bi-folder me-2"></i>
          {t("pages.workflow.auditDocuments")}
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ minWidth: '320px' }}>
          {enrichedCategories.map((category, index) => (
            <React.Fragment key={category.title}>
              {index > 0 && <Dropdown.Divider />}
              <Dropdown.Header>
                <div className="fw-bold">{category.title}</div>
                <small className="text-muted">{category.description}</small>
              </Dropdown.Header>
              
              {category.documents.map((doc) => {
                const isGeneratable = doc.canGenerate && canGenerateDocument(doc.key);
                
                return doc.url ? (
                  <Dropdown.Item 
                    key={doc.name}
                    onClick={() => handleDocumentClick(doc)}
                    className="document-item py-2"
                  >
                    <div className="d-flex align-items-start">
                      <i className="bi bi-eye me-2 mt-1"></i>
                      <div className="flex-grow-1">
                        <div className="document-name">{doc.name}</div>
                        <small className="text-muted d-block">{doc.description}</small>
                      </div>
                    </div>
                  </Dropdown.Item>
                ) : isGeneratable ? (
                  <div key={doc.name} className="px-3 py-2">
                    <div 
                      className="document-item" 
                      role="button"
                      onClick={() => handleGenerateClick(doc.key)}
                    >
                      <div className="d-flex align-items-start">
                        <i className="bi bi-plus-circle text-primary me-2 mt-1 generation-icon"></i>
                        <div className="flex-grow-1">
                          <div className="document-name">{doc.name}</div>
                          <small className="text-muted d-block">{doc.description}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Dropdown.Item 
                    key={doc.name}
                    onClick={() => handleUploadClick(doc)}
                    className="document-item py-2"
                  >
                    <div className="d-flex align-items-start">
                      <i className="bi bi-upload me-2 mt-1 text-primary"></i>
                      <div className="flex-grow-1">
                        <div className="document-name">{doc.name}</div>
                        <small className="text-muted d-block">{doc.description}</small>
                      </div>
                    </div>
                  </Dropdown.Item>
                );
              })}
            </React.Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Modal
        show={showFECViewer}
        onHide={() => setShowFECViewer(false)}
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center gap-3">
            {t("general.fecFileViewer")} -
            <Button 
              variant="outline-primary"
              onClick={() => exportFEC()}
              className="d-flex align-items-center gap-2"
              size="sm"
            >
              <i className="bi bi-filetype-xls"></i>
              {t("general.download")}
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FECViewer 
            dataroomId={dataroomId} 
            token={token} 
            onExport={setExportFEC}
          />
        </Modal.Body>
      </Modal>

      <GenerateDocumentModal
        show={generateModal.show}
        documentInfo={generateModal.document}
        onClose={() => setGenerateModal({ show: false, document: null })}
        onGenerate={() => {
          onGenerateDocument?.(generateModal.document.type);
          setGenerateModal({ show: false, document: null });
        }}
        token={token}
        dataroomId={dataroomId}
        onRefetchAudit={onRefetchAudit}
      />

      <UploadSpecificDocumentModal
        show={uploadModal.show}
        documentInfo={uploadModal.document}
        onClose={() => setUploadModal({ show: false, document: null })}
        onUpload={(file) => {
          if (uploadModal.document) {
            onUploadDocument?.(uploadModal.document.type, file);
            setUploadModal({ show: false, document: null });
          }
        }}
        token={token}
        dataroomId={dataroomId}
        onRefetchAudit={onRefetchAudit}
      />
    </>
  );
};

export default AuditDocumentsMenu;