import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button } from 'react-bootstrap';
import api from '../../../services/api';
import { API_ROUTES } from '../../../services/apiRoutes';

import { useUser } from '../../../general/UserProvider';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { marked } from 'marked';
import { cleanMarkdown } from '../../../utils/Markdown';

import SideTab from './SideTab';
import ContentEditor from './ContentEditor';

import './WSCardContent.css';

function WSCardContent({ title: initialTitle, setTitle, content: initialContent, setContent, noteId, closeModal, deleteNote, noteInfo }) {
    const { t } = useTranslation();
    const canUpdate = noteInfo && noteInfo.permissions ? noteInfo.permissions.Update : false;

    const { token } = useUser();
    const [activeTab, setActiveTab] = useState('WSContentComments');
    
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingTitle, setIsEditingTitle] = useState(false);

    const [editedContent, setEditedContent] = useState(cleanMarkdown(initialContent));
    const [currentContent, setCurrentContent] = useState(cleanMarkdown(initialContent));
    const [editedTitle, setEditedTitle] = useState(initialTitle);
    const [currentTitle, setCurrentTitle] = useState(initialTitle);
    const [copySuccess, setCopySuccess] = useState(false);   

    const preprocessMath = (text) => {
        // Convert \[...\] to $$...$$
        let processedText = text.replace(/\\\[([\s\S]*?)\\\]/g, (match, p1) => `$$${p1}$$`);
        // Convert \(...\) to $...$
        processedText = processedText.replace(/\\\(([\s\S]*?)\\\)/g, (match, p1) => `$${p1}$`);
        return processedText;
    };

    useEffect(() => {
        const cleanedContent = cleanMarkdown(initialContent);
        const processedContent = preprocessMath(cleanedContent);
        setCurrentContent(processedContent);
        setEditedContent(processedContent);
    }, [initialContent]);

    const updateNote = useCallback(async (noteId, title, content) => {
        if(!token) return;
    
        try {
            const response = await api.put(API_ROUTES.UPDATE_NOTE_BY_ID(noteId), { title, content });
    
            return response.data;
        } catch (error) {
            console.error("Error while updating the note: ", error);
        }
    }, [token]);

    const editingStates = useMemo(() => ({
        title: [isEditingTitle, setIsEditingTitle, currentTitle, setCurrentTitle, editedTitle, setEditedTitle],
        content: [isEditing, setIsEditing, currentContent, setCurrentContent, editedContent, setEditedContent]
    }), [isEditing, isEditingTitle, currentTitle, editedTitle, currentContent, editedContent]);

    const handleEdit = useCallback((type) => {
        const [, setIsEditing] = editingStates[type];
        setIsEditing(true);
    }, [editingStates]);
    
    const handleSave = useCallback(async (type) => {
        const [, setIsEditing, , setCurrent, edited] = editingStates[type];
        try {
            if (type === 'title') {
                await updateNote(noteId, edited, currentContent);
                setTitle(edited);
            } else if (type === 'content') {
                const processedContent = preprocessMath(edited);
                await updateNote(noteId, currentTitle, processedContent);
                setContent(processedContent);
            }
        } catch (error) {
            console.error("Error while updating the note.");
        }

        setCurrent(edited);
        setIsEditing(false);
    }, [editingStates, currentTitle, currentContent, noteId, setTitle, setContent, updateNote]);
    
    const handleCancelEdit = useCallback((type) => {
        const [, setIsEditing] = editingStates[type];
        setIsEditing(false);
    }, [editingStates]);

    const handleCopyToClipboard = useCallback(() => {
        const htmlContent = marked(currentContent);
        
        navigator.clipboard.write([
            new ClipboardItem({
                "text/html": new Blob([htmlContent], {type: "text/html"}),
                "text/plain": new Blob([htmlContent], {type: "text/plain"})
            })
        ]).then(() => {
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 3000);
        }).catch(err => {
            console.error('Error while copying the text:', err);
        });
    }, [currentContent]);
    
    const renderEditableContent = useCallback((type, displayComponent) => {
        const [isEditingType, , , , edited, setEdited] = editingStates[type];

        if (isEditingType && canUpdate) {
            return <ContentEditor 
                isEditing={isEditing}
                type={type}
                edited={edited}
                handleSave={handleSave}
                handleCancelEdit={handleCancelEdit}
                setEdited={setEdited} 
                originalValue={type === 'content' ? currentContent : currentTitle}
            />;
        } else {
            return (
                <>
                    <div onClick={() => canUpdate && handleEdit(type)} className="view-content">
                        {type === 'content' ? (
                            <ReactMarkdown 
                                remarkPlugins={[remarkGfm, remarkMath]}
                                rehypePlugins={[rehypeKatex]}
                                components={{
                                    code: ({node, inline, className, children, ...props}) => {
                                        console.log("Code block detected:", {inline, className, children});
                                        return <code className={className} {...props}>{children}</code>;
                                    },
                                    math: ({value}) => {
                                        console.log("Math component rendered:", value);
                                        return <span>{value}</span>;
                                    }
                                }}
                            >
                                {displayComponent}
                            </ReactMarkdown>
                        ) : displayComponent}
                    </div>
                    {type === 'content' && (
                        <Button variant="secondary" onClick={handleCopyToClipboard}>
                            {copySuccess 
                                ? <>
                                    <i className="bi bi-clipboard-check icon-spacing"></i> {t("pages.workspace.copySuccess")}
                                </>
                                : <>
                                    <i className="bi bi-clipboard icon-spacing"></i> {t("pages.workspace.copy")}
                                </>
                            }
                        </Button>
                    )}
                </>
            );
        }
    }, [handleEdit, handleSave, handleCancelEdit, editingStates, isEditing, handleCopyToClipboard, copySuccess, currentContent, currentTitle, canUpdate, t]);

    return (
        <Container fluid>
            <Row>
                <Col sm={8} className="h-100 p-2">
                    {renderEditableContent('title', <h1>{currentTitle}</h1>)}
                    {renderEditableContent('content', currentContent)}
                </Col>
                <SideTab
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    noteInfo={noteInfo} 
                    closeModal={closeModal}
                    deleteNote={deleteNote}
                />
            </Row>
        </Container>
    );
}

export default WSCardContent;