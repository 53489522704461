import React, { useState } from 'react';
import RequestsToolbar from '../components/RequestsToolbar';
import QuestionRequestTable from './QuestionRequestTable';

// Données factices pour les questions
const MOCK_QUESTIONS = [
    { 
        id: 1, 
        subject: 'Clarification on depreciation method',
        status: 'pending',
        requestDate: '2024-01-05',
        priority: 'high',
        control: 'Fixed Assets',
        deadline: '2024-01-15'
    },
    { 
        id: 2, 
        subject: 'Details about exceptional items',
        status: 'sent',
        requestDate: '2024-01-03',
        priority: 'medium',
        control: 'P&L Analysis',
        deadline: '2024-01-20'
    },
    { 
        id: 3, 
        subject: 'Bank reconciliation differences',
        status: 'answered',
        requestDate: '2024-01-02',
        priority: 'low',
        control: 'Cash',
        deadline: '2024-01-12'
    }
];

const QuestionRequestList = () => {
    const [selectedRequests, setSelectedRequests] = useState(new Set());
    const [filter, setFilter] = useState('');
    const [requests, setRequests] = useState(MOCK_QUESTIONS);

    const handleNewRequest = () => {
        // TODO: Ouvrir modal pour nouvelle question
        console.log('New question request');
    };

    const handleSendSelected = () => {
        console.log('Send selected questions', selectedRequests);
    };

    const handleDeleteSelected = () => {
        setRequests(requests.filter(req => !selectedRequests.has(req.id)));
        setSelectedRequests(new Set());
    };

    return (
        <div>
            <RequestsToolbar
                onNewRequest={handleNewRequest}
                onSendSelected={handleSendSelected}
                onDeleteSelected={handleDeleteSelected}
                selectedCount={selectedRequests.size}
                onFilterChange={setFilter}
                type="question"
            />
            <QuestionRequestTable
                requests={requests}
                selectedRequests={selectedRequests}
                onSelectionChange={setSelectedRequests}
                filter={filter}
            />
        </div>
    );
};

export default QuestionRequestList;