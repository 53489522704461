import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListGroup, Button } from 'react-bootstrap';
import './ThreadSidebar.css';

const ThreadSidebar = ({ 
  threads, 
  activeThread, 
  onThreadSelect, 
  onDeleteThread,
  isLoading 
}) => {
  const { t } = useTranslation();
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();
    
    if (date.toDateString() === today.toDateString()) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
  };

  const handleThreadClick = async (threadId) => {
    try {
      onThreadSelect(threadId);
    } catch (error) {
      console.error('Failed to load thread messages:', error);
    }
  };

  const handleDelete = async (e, threadId) => {
    e.stopPropagation();
    try {
      await onDeleteThread(threadId);
    } catch (error) {
      alert('Failed to delete thread');
      console.error('Failed to delete thread:', error);
    }
  };

  return (
    <div className="thread-sidebar">
      <div className="thread-sidebar-header">
        <h5 className="mb-0 fw-semibold">{t("pages.copilot.conversations")}</h5>
        <Button 
          variant="primary" 
          size="sm" 
          className="new-thread-btn"
          onClick={() => onThreadSelect(null)}
        >
          <i className="bi bi-plus-lg me-1"></i>
          <span>{t("pages.copilot.newThread")}</span>
        </Button>
      </div>

      <ListGroup variant="flush" className="thread-list">
        {isLoading ? (
          <div className="text-center p-3">
            <div className="spinner-border spinner-border-sm text-primary" role="status">
              <span className="visually-hidden">{t("loading")}</span>
            </div>
          </div>
        ) : (
          <>
            {threads.map((thread) => (
              <ListGroup.Item
                key={thread.id}
                as="div"
                className={`thread-item ${thread.id === activeThread ? 'active' : ''}`}
                onClick={() => handleThreadClick(thread.id)}
              >
                <div className="thread-item-content">
                  <div className="thread-main-content">
                    <div className="thread-title-row">
                      <span className="thread-title">
                        {thread.title || 'New Conversation'}
                      </span>
                      <div className="thread-actions">
                        <small className="thread-time">
                          <i className="bi bi-clock me-1"></i>
                          {formatDate(thread.lastUpdate || Date.now())}
                        </small>
                        <button
                          className="delete-button"
                          onClick={(e) => handleDelete(e, thread.id)}
                          title="Delete thread"
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
            {threads.length === 0 && (
              <div className="text-muted">
                <i className="bi bi-chat-left me-2"></i>
                {t("pages.copilot.noConversations")}
              </div>
            )}
          </>
        )}
      </ListGroup>
    </div>
  );
};

export default ThreadSidebar;