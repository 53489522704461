import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'react-bootstrap';

import SimpleMDE from 'simplemde';
import "simplemde/dist/simplemde.min.css";

const WSCreateNoteForm = ({ createNote, folders, loadingFolders, errorFolders }) => {
    const { t } = useTranslation();
    const simpleMdeInstance = useRef(null);

    const [formData, setFormData] = useState({
        title: '',
        content: '',
        selectedFolders: []
    });
    const [errors, setErrors] = useState({ title: '', content: '' });

    const validateForm = () => {
        let isValid = true;
        let errors = { title: '', content: '' };

        if (!formData.title.trim()) {
            errors.title = t("pages.workspace.titleRequired");
            isValid = false;
        }

        if (!formData.content.trim()) {
            errors.content = t("pages.workspace.contentRequired");
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    useEffect(() => {
        if (!simpleMdeInstance.current) {
            const simplemde = new SimpleMDE({ 
                element: document.getElementById("simplemde"),
                initialValue: ""
            });
    
            simplemde.codemirror.on("change", () => {
                setFormData(prevFormData => ({ ...prevFormData, content: simplemde.value() }));
            });
    
            simpleMdeInstance.current = simplemde;
        }
    
        return () => {
            if (simpleMdeInstance.current) {
                simpleMdeInstance.current.toTextArea();
                simpleMdeInstance.current = null;
            }
        };
    }, []); 
    
    useEffect(() => {
        if (simpleMdeInstance.current && simpleMdeInstance.current.value() !== formData.content) {
            simpleMdeInstance.current.value(formData.content);
        }
    }, [formData.content]);

    const generateFolderOptions = useCallback((folders, level = 0) => {
        let options = [];
        folders.forEach(folder => {
            options.push(
                <option key={folder.id} value={folder.id}>
                    {`${'—'.repeat(level)} ${folder.name}`}
                </option>
            );
            if (folder.subcategories && folder.subcategories.length > 0) {
                options = options.concat(generateFolderOptions(folder.subcategories, level + 1));
            }
        });
        return options;
    }, []);
    

    const handleFolderChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => parseInt(option.value));
        setFormData(prevFormData => ({ ...prevFormData, selectedFolders: selectedOptions }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); 

        if (validateForm()) {
            try {
                await createNote(formData.title, formData.content, formData.selectedFolders);
                setFormData({ title: '', content: '', selectedFolders: [] });
                setErrors({ title: '', content: '' });
            } catch (error) {
                console.error("Error adding note:", error);
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Label>{t("general.title")}</Form.Label>
                <Form.Control 
                    type="text" 
                    value={formData.title} 
                    onChange={e => setFormData({ ...formData, title: e.target.value })}
                    isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.title}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>{t("general.content")}</Form.Label>
                <Form.Control 
                    as="textarea" 
                    rows={3} 
                    id="simplemde" 
                    value={formData.content}
                    onChange={e => setFormData({ ...formData, content: e.target.value })}
                    isInvalid={!!errors.content}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.content}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>{t("general.attachFolders")}</Form.Label>
                <Form.Control 
                    as="select" 
                    multiple 
                    value={formData.selectedFolders} 
                    onChange={handleFolderChange}>
                    {loadingFolders ? <option>{t("loading")}</option> : generateFolderOptions(folders)}
                </Form.Control>
                {errorFolders && <p className="text-danger">{t("error.title")}</p>}
            </Form.Group>

            <Button variant="dark" type="submit">
                {t("general.submit")}
            </Button>
        </Form>
    );
};

export default WSCreateNoteForm;
