import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Card, Spinner, Form, Pagination, Row, Col } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import './FECViewer.css';
import { useFECData } from '../../hooks/workflow/useFECData';

const FEC_HEADERS = [
    { key: 'JournalCode', label: 'JournalCode', description: 'Code journal de l\'écriture' },
    { key: 'JournalLib', label: 'JournalLib', description: 'Libellé journal' },
    { key: 'EcritureNum', label: 'EcritureNum', description: 'N° de l\'écriture' },
    { key: 'EcritureDate', label: 'EcritureDate', description: 'Date de comptabilisation' },
    { key: 'CompteNum', label: 'CompteNum', description: 'N° compte' },
    { key: 'CompteLib', label: 'CompteLib', description: 'Libellé compte' },
    { key: 'CompAuxNum', label: 'CompAuxNum', description: 'N° compte auxiliaire' },
    { key: 'CompAuxLib', label: 'CompAuxLib', description: 'Libellé compte auxiliaire' },
    { key: 'PieceRef', label: 'PieceRef', description: 'Référence pièce' },
    { key: 'PieceDate', label: 'PieceDate', description: 'Date pièce' },
    { key: 'EcritureLib', label: 'EcritureLib', description: 'Libellé écriture' },
    { key: 'Debit', label: 'Debit', description: 'Montant débit', type: 'number' },
    { key: 'Credit', label: 'Credit', description: 'Montant crédit', type: 'number' },
    { key: 'EcritureLet', label: 'EcritureLet', description: 'Lettrage' },
    { key: 'DateLet', label: 'DateLet', description: 'Date lettrage' },
    { key: 'ValidDate', label: 'ValidDate', description: 'Date validation' },
    { key: 'Montantdevise', label: 'Montantdevise', description: 'Montant devise' },
    { key: 'Idevise', label: 'Idevise', description: 'ID devise' }
];

const FECViewer = ({ dataroomId, token, onExport }) => {
    const { t } = useTranslation();
    const { entries, fecInfo, loading, error } = useFECData(token, dataroomId);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const ROWS_PER_PAGE = 50;

    const isNumericColumn = (key) => ['Debit', 'Credit', 'Montantdevise'].includes(key);
    const isDateColumn = (key) => key.includes('Date');

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };

    useEffect(() => {
        if (onExport && entries && fecInfo) {
            // On se contente de définir la fonction sans l'exécuter
            const exportFunction = () => {
                const excelData = entries.map(row => {
                    const formattedRow = {};
                    FEC_HEADERS.forEach(header => {
                        if (isNumericColumn(header.key)) {
                            formattedRow[header.key] = row[header.key]?.toFixed(2) || '0.00';
                        } else if (isDateColumn(header.key)) {
                            formattedRow[header.key] = formatDateForExport(row[header.key]);
                        } else {
                            formattedRow[header.key] = row[header.key] || '';
                        }
                    });
                    return formattedRow;
                });
            
                const worksheet = XLSX.utils.json_to_sheet(excelData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "FEC");
                XLSX.writeFile(workbook, `FEC_${fecInfo?.fiscal_year || 'export'}.xlsx`);
            };
            
            onExport(() => exportFunction); 
        }
    }, [entries, fecInfo, onExport]);

    const formatDateForExport = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0].replace(/-/g, '');
    };

    if (loading) {
        return (
            <div className="text-center p-4">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">{t("loading")}</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-3">
                <div className="alert alert-danger">{error}</div>
            </div>
        );
    }

    const filteredData = entries.filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const totalPages = Math.ceil(filteredData.length / ROWS_PER_PAGE);
    const currentData = filteredData.slice(
        (currentPage - 1) * ROWS_PER_PAGE,
        currentPage * ROWS_PER_PAGE
    );

    return (
        <div className="fec-viewer-container p-2">
            <Card className="fec-summary mb-3">
                <Card.Body>
                    <Row className="align-items-center">
                        <Col md={8}>
                            <div className="d-flex align-items-center mb-2">
                                <h5 className="mb-0 me-3">{t("pages.documents.fec.name")}</h5>
                                <span className="badge bg-secondary">FY {fecInfo?.fiscal_year}</span>
                            </div>
                            <Row className="g-3">
                                <Col md={4}>
                                    <div className="stat-item">
                                        <div className="stat-label">{t("general.period")}</div>
                                        <div className="stat-value">
                                            {formatDate(fecInfo?.start_date)} - {formatDate(fecInfo?.end_date)}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="stat-item">
                                        <div className="stat-label">{t("general.totalEntries")}</div>
                                        <div className="stat-value">{fecInfo?.number_of_entries?.toLocaleString()}</div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="stat-item">
                                        <div className="stat-label">{t("general.balance")}</div>
                                        <div className="stat-value">
                                            <span className={fecInfo?.balance === 0 ? 'text-success' : 'text-danger'}>
                                                {fecInfo?.balance?.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4} className="d-flex align-items-center justify-content-center">
                            <Form.Control
                                type="search"
                                placeholder={t("general.search")}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="w-100"
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
     
            <div className="fec-viewer-content" style={{ overflowY: 'auto' }}>
                <div className="table-responsive">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                {FEC_HEADERS.map((header) => (
                                    <th key={header.key} title={header.description}>{header.label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map((row) => (
                                <tr key={row.id}>
                                    {FEC_HEADERS.map((header) => (
                                        <td 
                                            key={`${row.id}-${header.key}`}
                                            className={isNumericColumn(header.key) ? 'text-end' : ''}
                                        >
                                            {isNumericColumn(header.key)
                                                ? row[header.key]?.toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                                                : isDateColumn(header.key)
                                                    ? formatDate(row[header.key])
                                                    : row[header.key]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
     
            <div className="fec-viewer-footer p-2 border-top">
                <div className="d-flex justify-content-between align-items-center">
                    <small>
                        {t("general.showingEntries")} {((currentPage - 1) * ROWS_PER_PAGE) + 1} - {Math.min(currentPage * ROWS_PER_PAGE, filteredData.length)} {t("general.of")} {filteredData.length}
                    </small>
                    <Pagination className="mb-0">
                        <Pagination.First
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(1)}
                        />
                        <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                        />
                        <Pagination.Item active>{currentPage}</Pagination.Item>
                        <Pagination.Next
                            disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(currentPage + 1)}
                        />
                        <Pagination.Last
                            disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(totalPages)}
                        />
                    </Pagination>
                </div>
            </div>
        </div>
    );
};

export default FECViewer;